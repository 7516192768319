import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(
        @Inject('BASE_URL') private _baseUrl: string,
        private _cookieService: CookieService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isRelativePath = req.url.includes('/assets') || req.url.startsWith('http://') || req.url.startsWith('https://');
        if (isRelativePath) {
            return next.handle(req);
        }
        if (req.headers.get('skip')) {
            return next.handle(req);
        }
        let params: HttpParams = (req.params) ? req.params : new HttpParams();
        let headers: HttpHeaders = (req.headers) ? req.headers : new HttpHeaders();
        const url = `${this._baseUrl}${req.url}`;
        if (!params.has('authorization') || (params.has('authorization') && params.get('authorization') === 'true')) {
            const accessToken: string = this._cookieService.get('token');
            const cId: string = this._cookieService.get('c_id');
            headers = headers.append('token', accessToken);
            headers = headers.append('c-id', cId);
        }
        if (params.has('authorization')) {
            params = params.delete('authorization');
        }
        const clonedReq = req.clone({
            url,
            headers,
            params
        });
        return next.handle(clonedReq);
    }
}
