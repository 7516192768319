import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-page-heading',
  templateUrl: './page-heading.component.html',
  styleUrls: ['./page-heading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageHeadingComponent implements OnInit {
  @Output() add: EventEmitter<void> = new EventEmitter<void>();
  @Input() title: string = '';
  @Input() sticky: boolean = false;
  @Input() buttonText: string = 'Add New';
  @Input() hideButton: boolean = false;
  @Input() backUrl: string;
  @Input() showBackButton: boolean = false;
  @Input() keepQueryParameters: boolean = false;
  public companyName: string;

  constructor(
    private _cookieService: CookieService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.companyName = this._cookieService.get('company_name');
  }

  public onAddClicked(): void {
    this.add.emit();
  }

  public onBackClick(): void {
    if (this.keepQueryParameters) {
      this._router.navigate([this.backUrl], { queryParamsHandling: 'merge' });
    } else {
      this._router.navigate([this.backUrl]);
    }
  }

}
