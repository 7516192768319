import { ILanguage } from '@models/languages/language';

export class StockGeneralTranslation {
    item_detail_page_message_json: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.item_detail_page_message_json = {
                ...this.item_detail_page_message_json,
                [language.code]: ''
            };
        });
    }
}
