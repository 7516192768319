import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IStatusPageResponse } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class StatusPageService {

  constructor(private _httpClient: HttpClient) { }

  public get(page?: number, key?: string, value?: string) {
    let params = new HttpParams();
    if (key) {
      params = params.append('orderBy', key);
      params = params.append('by', value === 'ascend' ? 'ASC' : 'DESC');
    }
    params = params.append('page', String(page));
    return this._httpClient.get<ApiResponse<IStatusPageResponse>>(`integration-get-statuses`, { params });
  }

  public search(search: string) {
    const params = new HttpParams().append('search', search);
    return this._httpClient.get<ApiResponse<IStatusPageResponse>>(`integration-search`, { params });
  }

}
