<div>
  <p *ngIf="!showTooltip" class="controls-label mb-2" [class.font-weight-700]="bold">
    {{label}}
  </p>
  <p *ngIf="showTooltip" class="controls-label mb-2" [style.max-width]="textMaxWidth" [class.font-weight-700]="bold"
    [ngStyle]="{'overflow': overflow }" nz-tooltip [nzTooltipTitle]="label">{{label}}</p>
  <div class="d-flex" [class.flex-row-reverse]="reversed">
    <button class="picker" [class.mr-1]="!reversed" [class.ml-1]="reversed" #ignoredButton nz-button nzType="primary"
      [ngStyle]="{'background':control.value}" (click)="toggle=!toggle">
      <i nz-icon nzType="menu" nzTheme="outline" ngClass="{{!control.value?'d-block':'d-none'}}"></i>
    </button>
    <input nz-input #ignoredInput [value]="control.value" (colorPickerChange)="control.setValue($event)"
      [formControl]="control" [cpIgnoredElements]="[ignoredButton, ignoredInput]" [(cpToggle)]="toggle"
      [colorPicker]="control.value" />
  </div>
</div>