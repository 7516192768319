import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Directive({
  selector: '[appAutofocuse]'
})
export class AutofocuseDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
  }
  ngAfterViewInit() {
    of([]).pipe(
      delay(500)
    ).subscribe(_ => {
      this.el.nativeElement.focus();
    });
  }
}
