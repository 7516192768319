export const PAYPAL_NAMES: string[] = [
    'Visa / Mastercard',
    'Visa / Mastercard / Amex',
    'PayPal'
];

export const PAYPAL_IMAGES: { label: string; image: string }[] = [
    {
        image: 'https://seek-unique-co.s3.amazonaws.com/373f64b6sadasd9123874bc225c443/payments/62222355946b8_62222355946ba_visa-mastercard.png',
        label: 'Visa / Mastercard'
    },
    {
        image: 'https://seek-unique-co.s3.amazonaws.com/373f64b6sadasd9123874bc225c443/payments/6222235ed7981_6222235ed7983_visa-mastercard-amex.png',
        label: 'Visa / Mastercard / Amex'
    },
    {
        image: 'https://seek-unique-co.s3.amazonaws.com/373f64b6sadasd9123874bc225c443/payments/62206fbc8a481_62206fbc8a484_paypal-784404__480_1.png',
        label: 'Paypal'
    }
];
