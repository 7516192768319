import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class IntegrationItemService {

  constructor(private _httpClient: HttpClient) { }

  public get(id: string, key: string) {
    return this._httpClient.get<{has: number, is_logged_in: boolean}>(`integration-get-stock-data/${id}?key=${key}`);
  }

  public delete(id: string, key: string) {
    return this._httpClient.delete(`integration-delete-stock/${id}?key=${key}`);
  }

}
