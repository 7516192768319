import { ILanguage } from '@models/languages/language';

export class CategoryTranslation {
    category_name_json: { [key: string]: string };
    category_description_json: { [key: string]: string };
    category_keywords_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.category_name_json = {
                ...this.category_name_json,
                [language.code]: ''
            };
            this.category_description_json = {
                ...this.category_description_json,
                [language.code]: ''
            };
            this.category_keywords_json = {
                ...this.category_keywords_json,
                [language.code]: ''
            };
        });
    }
}
