import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _id: number = 0;
  constructor(
    private _notifierSrv: NotifierService,
    private _translateSrv: TranslateService
  ) { }

  /**
   * show the notification window at the top right corner
   * 
   * @param message the notification message
   * @param options the notification options`(optional)`
   *  - `type` - `'success' | 'warning'` by default `'success'`
   *  - `id` - unique string
   *  - `template` - the notification custom container`(optional)`
   *
   * @return `Promose<string>`
   * 
   * id of the current notification container
   */
  private async _show(
    message: string,
    options?: {
      type: 'success' | 'warning',
      id?: string;
      template?: TemplateRef<any>;
    }
  ): Promise<string> {

    // get unique id for the notifier
    const notiferId = options?.id || `notifier-${++this._id}`;

    // default otions for the notifer
    const defaultOptions = {
      type: 'success',
      id: notiferId
    };

    // merge the options with the default options
    options = { ...defaultOptions, ...options, type: 'success' };


    // show notification
    this._notifierSrv.show({
      message: await this._translateSrv.get(message).toPromise(),
      ...options,
    });

    // return notification id
    return notiferId;
  }

  /**
   * show the warning notification
   *
   * @param message the notification message
   * @param options the notification options`(optional)`
   *  - `id` - unique string
   *  - `template` - the notification custom container`(optional)`
   *
   * @return `Promose<string>`
   * 
   * id of the current notification container
   */
  warning(
    message: string,
    options?: {
      id?: string;
      template?: TemplateRef<any>;
    }
  ) {
    return this._show(message, { type: 'warning', ...options });
  }

  /**
   * show the warning notification
   *
   * @param message the notification message
   * @param options the notification options`(optional)`
   *  - `id` - unique string
   *  - `template` - the notification custom container`(optional)`
   *
   * @return `Promose<string>`
   * 
   * id of the current notification container
   */
  success(
    message: string,
    options?: {
      id?: string;
      template?: TemplateRef<any>;
    }
  ) {
    return this._show(message, { type: 'success', ...options });
  }
}
