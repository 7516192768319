export const environment = {
  production: false,
  // API_URL: 'https://dev01.seekunique.co/api/',
  API_URL: 'https://staging.seekunique.co/api/',
  INTEGRATION_URL: 'https://dev01integration.seekunique.co/',
  // INTEGRATION_URL: 'https://integration.seekunique.co/',
  // INTEGRATION_URL: 'http://localhost:4002/',
  translation: {
    default: 'en',
    languages: [
      { name: 'English', key: 'en' },
      { name: 'Russian', key: 'ru' }
    ]
  },
  PICSART_API_TOKEN: '2yFftArNa4Qlqqu5t0Ahgzb5gEvFBf55',
  PICSART_URL: 'https://api.picsart.io/tools/demo/removebg'
  // reserve_api -> irnvEfzdtHdTZ8GvmKylS9ii4U1SoQdb
};
