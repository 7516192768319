import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@services/index';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loading$: Observable<boolean>;

  constructor(
    private _translateService: TranslateService,
    private _loadingService: LoadingService,
  ) {
    this._translateService.setDefaultLang(environment.translation.default);
  }

  ngOnInit(): void {
    this.loading$ = this._loadingService.listenGlogalLoading();
  }
}
