import { _isNumberValue } from '@angular/cdk/coercion';
import { IStockLocationHistory } from '@models/index';
import { DateHelper } from './date-helper';

export class LocationHistoryHelper {
  /**
   * store the current location item
   */
  private _currentLocation: IStockLocationHistory;
  private _invalidLocationsByIds: {
    [key: string]: {
      overlap: 'start' | 'end' | 'outside' | 'inside';
      overlapWith: IStockLocationHistory;
    }
  } = {};

  /**
   * limit of the days for the future comparitions
   */
  futureDaysDiff: number = 7;
  /**
   * limit of the days for the overdue comparitions
   */
  overdueDaysDiff: number = 1;

  constructor(private _historyItems: IStockLocationHistory[]) {
    // the _historyItems should be an array
    _historyItems = _historyItems || [];

    this._initHistoryItems();

    // find and store the current location item
    this._storeCurrentLocationItem();
  }

  /**
   * prepare the history items
   *
   * @param null 
   *
   * @return `void`
   */
  private _initHistoryItems(): void {
    this._invalidLocationsByIds = {};
    this._historyItems.forEach(h => {
      // debugger;
      h.days_due = null;

      // calculate and store days due for each location history item
      // invalid should be checked
      if (h.date_return_due) {
        const start = h.date_returned ? new Date(h.date_returned) : new Date();
        const endDate = new Date(h.date_return_due);
        h.days_due = DateHelper.diffInDays(start, endDate);
      }

      // validate each item and save overlaps
      const validationError = this.validate(h);

      if (validationError) {
        const overlap = validationError.overlap;
        // when the error comes from date returned then the item is valid
        if (overlap === 'start' || overlap === 'outside' && h.date_returned) {
          return;
        }

        this._invalidLocationsByIds[h.stock_location_id] = validationError;
      }
    });
  }

  /**
   * find and store the current location
   *
   * @param null 
   *
   * @return `IStockLocationHistory`
   */
  private _storeCurrentLocationItem(): void {
    const locations = this._historyItems.filter(i => {
      if (!i.date_delivery) {
        // when the returned date is in past filter out 
        if (i.date_returned) {
          const returnedDate = new Date(i.date_returned);
          const now = new Date();
          const diff = DateHelper.diffInDays(now, returnedDate);
          if (diff < 0) { return false; }
        }
        return true;
      }

      const deliveryDate = new Date(i.date_delivery);

      const today = new Date();
      const dueDate = new Date(i.date_returned || i.date_return_due);
      const startDate = DateHelper.diffInDays(today, deliveryDate);
      const endDate = DateHelper.diffInDays(today, dueDate);

      return startDate <= 0 && (endDate >= 0 || !i.date_returned);
    });

    let currentLocation = null;
    locations.forEach(location => {
      // when the item doesn't have delivery date 
      if (!location.date_delivery) {
        // when the currentLocation is null tne just get the location as current
        if (!currentLocation) {
          currentLocation = location;
          return;
        }

        // do nothing 
        // when the current location has date delivery and 
        // the item in loop doesn't
        if (currentLocation?.date_delivery) { return; }

        // othervise compare the created date times
        // the latest created location which doesn't have dates 
        // should be the current by default
        const locationCreatedDate = new Date(location.created_datetime);
        const currentCreatedDate = new Date(currentLocation.created_datetime);
        if (currentCreatedDate.getTime() < locationCreatedDate.getTime()) {
          currentLocation = location;
        }

        return;
      }

      // this part will work when the location in loop has delivery date
      // then it should be compared with the currentLocation
      // here we should get the one with the lowest value of days due
      if (
        !_isNumberValue(currentLocation?.days_due) ||
        location.days_due < currentLocation?.days_due
      ) {

        const currentEndDate = currentLocation?.date_returned || currentLocation?.date_return_due;
        const currentLocationDaysDiff = DateHelper.diffInDays(new Date(), new Date(currentEndDate));

        const locationEndDate = location.date_returned || location.date_return_due;
        const locationDaysDiff = DateHelper.diffInDays(new Date(), new Date(locationEndDate));

        // get the closest location
        // when the currentEndDate is null or
        // when the current location diff is more than location diff
        if (!currentEndDate || currentLocationDaysDiff > locationDaysDiff) {
          currentLocation = location;
        }
      }
    });

    this._currentLocation = currentLocation;
  }

  public getList(): IStockLocationHistory[] { return this._historyItems; }

  public getCurrentLocation(): IStockLocationHistory { return this._currentLocation; }

  public isInvalidOne(location: IStockLocationHistory) {
    return location.stock_location_id in this._invalidLocationsByIds;
  }

  public isValidState() {
    return !Object.keys(this._invalidLocationsByIds).length;
  }

  /**
   * get corresponsding class for the `history` item
   *
   * @param history - `IStockLocationHistory`
   *
   * @return `string[]` 
   */
  public getClassesFor(location: IStockLocationHistory): string[] {
    const classes = ['simple-location-item'];

    // when the location is current
    if (location === this._currentLocation) {
      classes.push('current-location-item');
    }

    // when the location have specified dates
    if (_isNumberValue(location.days_due)) {

      // when the location is history item and is overdue
      if (location.date_returned && location.days_due < this.overdueDaysDiff) {
        classes.push('overdue-location-history-item');
      }

      // when the days due in the future
      if (location.days_due > this.futureDaysDiff) {
        classes.push('future-location-item');
      }

      // when days due in the current week
      if (
        location.days_due <= this.futureDaysDiff
        && location.days_due >= this.overdueDaysDiff
      ) {
        classes.push('pending-location-item');
      }

      // when days due is today or overdue
      if (location.days_due < this.overdueDaysDiff) {
        classes.push('overdue-location-item');
      }
    }

    return classes;
  }

  /**
   * the given `location` should be compared with all of the location items 
   * to make sure there is not any overlaps in the dates
   *
   * @param location - `IStockLocationHistory`
   *
   * @return `ValidationErrors | null`
   */
  public validate(location: IStockLocationHistory): {
    overlap: 'start' | 'end' | 'outside' | 'inside';
    overlapWith: IStockLocationHistory;
  } | null {

    // when the location doesn't have specified dates then 
    // no need to compare with other items
    if (!location.date_delivery && location.date_return_due) { return null; }

    // filter and keep only the neccessary locations
    // the ones which has specified dates
    // also no need to compare item by itself
    const locations = this._historyItems.filter(item => {
      const specifed = item.date_delivery && item.date_return_due;
      const notSame = item.stock_location_id !== location.stock_location_id;
      return specifed && notSame;
    });

    // the locations can be overlaped by 4 different ways
    // 1. at start:
    // 2. at end:
    // 3. inside:
    // 4. outside:

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < locations.length; i++) {
      const eachLocation = locations[i];
      const endDate = location.date_returned || location.date_return_due;
      const locationStart = new Date(location.date_delivery);
      const locationEnd = new Date(endDate);

      const eachEndDate = eachLocation.date_returned || eachLocation.date_return_due;
      const eachLocationStart = new Date(eachLocation.date_delivery);
      const eachLocationEnd = new Date(eachEndDate);

      // overlap at inside 
      const insideOverlapStart = DateHelper.diffInDays(eachLocationStart, locationStart);
      const insideOverlapEnd = DateHelper.diffInDays(eachLocationEnd, locationEnd);
      if (insideOverlapStart >= 0 && insideOverlapEnd <= 0) {
        return {
          overlap: 'inside',
          overlapWith: eachLocation,
        };
      }

      // overlap at outside 
      const outsideOverlapStart = DateHelper.diffInDays(locationStart, eachLocationStart);
      const outsideOverlapEnd = DateHelper.diffInDays(locationEnd, eachLocationEnd);
      if (outsideOverlapStart >= 0 && outsideOverlapEnd <= 0) {
        return {
          overlap: 'outside',
          overlapWith: eachLocation,
        };
      }

      // overlap at start 
      const startOverlapStart = DateHelper.diffInDays(locationStart, eachLocationStart);
      const startOverlapEnd = DateHelper.diffInDays(locationEnd, eachLocationStart);
      if (startOverlapStart >= 0 && startOverlapEnd <= 0) {
        return {
          overlap: 'start',
          overlapWith: eachLocation,
        };
      }

      // overlap at end 
      const endOverlapStart = DateHelper.diffInDays(locationStart, eachLocationEnd);
      const endOverlapEnd = DateHelper.diffInDays(locationEnd, eachLocationEnd);
      if (endOverlapStart >= 0 && endOverlapEnd <= 0) {
        return {
          overlap: 'end',
          overlapWith: eachLocation,
        };
      }


    }

    return null;
  }
}
