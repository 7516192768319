import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {BaseApiService} from '@api';
import {ISettings} from '@models/index';
import {StockLimitForWithoutLicense} from '@enums/index';
import {LicenceEnhancementsUnlimited} from '@enums/LicenceEnhancementsUnlimited';

@Injectable({
  providedIn: 'root'
})
export class StockLimitForWithoutLicenseGuard implements CanActivate {
  public settings: ISettings;
  constructor(private _baseAPI: BaseApiService,
              private _router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.settings = this._baseAPI.settings.settings;
    if (this.settings.licence_enhancements_unlimited === LicenceEnhancementsUnlimited.NOT_EXIST &&
      this.settings.stocks_count >= StockLimitForWithoutLicense.MAX) {
      this._router.navigate(['/dashboard/stock'], { queryParams: { search: '', status: '', currentPage: '1', searchType: 'basic'} });
      return false;
    }
    return true;
  }

}
