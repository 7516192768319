import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TranslateService } from '@ngx-translate/core';
import { IMatterPort } from '@models/utils/matterport';
import { BaseApiService } from '@api';
import { Subject } from 'rxjs';
import { IMatterports } from '@models/index';
import { MATTERPORT_URL_WITHOUT_ID } from '@constants/matterport';

@Component({
  selector: 'app-matterport',
  templateUrl: './matterport.component.html',
  styleUrls: ['./matterport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatterportComponent implements OnInit, OnDestroy {
  @Input() set matterports(data: IMatterPort[]) {
    if (data) {
      for (const matterport of data) {
        this._ids.push(matterport.id);
        this.urlList.push(matterport.embed_id);
      }
      this.outIdList.emit(this._ids);
    }
  }
  @Output() outIdList: EventEmitter<string[]> = new EventEmitter();

  private _unsubscribe$: Subject<void> = new Subject();
  private _ids: string[] = [];

  public matterport_url: FormControl = new FormControl([]);
  public urlList: string[] = [];
  public newTab: FormControl = new FormControl(true);
  public allMatterports: IMatterports[] = [];

  constructor(
    private _modalService: NzModalService,
    private _translateService: TranslateService,
    private _baseAPI: BaseApiService
  ) { }

  ngOnInit(): void {
    this._baseAPI.matterport.get()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(({ data }) => {
        this.allMatterports = data;
        this._selectedMatterports();
      });
  }

  private _selectedMatterports(): void {
      const indexList = [];
      for (const id of this._ids) {
        const index = this.allMatterports.findIndex(matterport => {
          return matterport.id === id;
        });
        if (index !== -1) {
          indexList.push(this.allMatterports[index]);
        }
      }
      this.matterport_url.setValue(indexList);
  }

  public goToMatterport(url: string): void {
    window.open(MATTERPORT_URL_WITHOUT_ID + url, '_blank');
  }

  public addNewUrl(): void {
    this._ids = [];
    this.urlList = [];
    for (const matterport of this.matterport_url.value) {
      this._ids.push(matterport.id);
      this.urlList.push(matterport.embed_id);
    }
    this.outIdList.emit(this._ids);
  }

  // public showModal(url: string, index: number) {
  //   this._modalService.create({
  //     nzContent: MatterportModalComponent,
  //     nzWidth: '500px',
  //     nzComponentParams: {url},
  //     nzOkText: this._translateService.instant('COMMON.SAVE'),
  //     nzCancelText: this._translateService.instant('COMMON.CANCEL'),
  //     nzOnOk: (instance: MatterportModalComponent) => {
  //       if (instance.matterport.invalid) {
  //         return false;
  //       }
  //       this.urlList[index] = instance.matterport.value;
  //       this._getId(instance.matterport.value, index);
  //       this.outIdList.emit(this.matterport_ids);
  //       return true;
  //     }
  //   });
  // }

  public removeUrl(index: number): void {
    this.urlList.splice(index, 1);
    this._ids.splice(index, 1);
    this._selectedMatterports();
    this.outIdList.emit(this._ids);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

}
