import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { BaseApiService } from '@api';

@Directive({
    selector: '[appIfRoles]'
})
export class IfRolesDirective implements OnInit {
    @Input() public appIfRoles: string[];

    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _templateRef: TemplateRef<any>,
        private _baseAPI: BaseApiService
    ) { }

    public ngOnInit(): void {

        const roles = [this._baseAPI.settings.settings.business_manager_old];

        if (!roles) {
            this._viewContainerRef.clear();
        }
        const idx = roles.findIndex((element) => this.appIfRoles.indexOf(element) !== -1);
        if (idx < 0) {
            this._viewContainerRef.clear();
        } else {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
        }
    }
}
