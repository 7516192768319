import { FormArray, FormControl, FormGroup } from '@angular/forms';

export class FormHelper {
    static markAllFieldsAsDirty(form: FormGroup | FormArray): void {
        Object.keys(form.controls).forEach(key => {
            const control = form.get(key);
            if (control instanceof FormControl) {
                form.controls[key].markAsDirty();
                form.controls[key].updateValueAndValidity();
            } else if (control instanceof FormGroup || control instanceof FormArray) {
                this.markAllFieldsAsDirty(control);
            }
        });
    }
}
