import { NgModule } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  AppstoreAddOutline,
  AppstoreOutline,
  ArrowLeftOutline,
  ArrowUpOutline,
  CameraOutline,
  CarOutline,
  CheckOutline,
  CloseCircleOutline,
  CloseOutline,
  ColumnHeightOutline,
  ContainerOutline,
  CopyOutline,
  DeleteOutline,
  DoubleLeftOutline,
  DoubleRightOutline,
  DownloadOutline,
  DragOutline,
  EditOutline,
  EyeInvisibleOutline,
  EyeOutline,
  FileDoneOutline,
  FileOutline,
  FilePdfOutline,
  FileTwoTone,
  FolderOpenOutline,
  GlobalOutline,
  InboxOutline,
  InfoCircleOutline,
  LeftOutline,
  LinkOutline,
  LoadingOutline,
  LogoutOutline,
  MenuOutline,
  MinusOutline,
  PictureOutline,
  PlusOutline,
  PlusSquareOutline,
  ProjectOutline,
  QrcodeOutline,
  ReloadOutline,
  RightOutline,
  SearchOutline,
  SelectOutline,
  SettingOutline,
  TeamOutline,
  UnorderedListOutline,
  UpOutline,
  UserAddOutline,
  UserOutline,
  WarningOutline
} from '@ant-design/icons-angular/icons';
import { NzIconModule } from 'ng-zorro-antd/icon';


const icons: IconDefinition[] = [
  DeleteOutline,
  EyeOutline,
  CheckOutline,
  LoadingOutline,
  PlusOutline,
  LinkOutline,
  SearchOutline,
  EyeInvisibleOutline,
  PlusSquareOutline,
  InboxOutline,
  DragOutline,
  InfoCircleOutline,
  UpOutline,
  EditOutline,
  QrcodeOutline,
  PictureOutline,
  FolderOpenOutline,
  CameraOutline,
  ReloadOutline,
  FilePdfOutline,
  FileDoneOutline,
  CopyOutline,
  CloseOutline,
  CloseCircleOutline,
  ColumnHeightOutline,
  ProjectOutline,
  UnorderedListOutline,
  AppstoreAddOutline,
  UserAddOutline,
  ArrowUpOutline,
  ContainerOutline,
  DownloadOutline,
  FileOutline,
  FileTwoTone,
  TeamOutline,
  CarOutline,
  GlobalOutline,
  RightOutline,
  DoubleRightOutline,
  LeftOutline,
  DoubleLeftOutline,
  SelectOutline,
  MinusOutline,
  UserOutline,
  MenuOutline,
  SettingOutline,
  AppstoreOutline,
  ArrowLeftOutline,
  LogoutOutline,
  WarningOutline
];


@NgModule({
  imports: [NzIconModule.forRoot(icons)],
  exports: [NzIconModule]
})
export class IconsModule { }
