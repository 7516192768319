import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ApiResponse,
    IColoursSetting, IColoursSettingUpdate,
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ColoursService {

    constructor(private _httpClient: HttpClient) { }

    public getColours() {
        return this._httpClient.get<IColoursSetting>('get-colours');
    }

    public updateColours(data: IColoursSettingUpdate) {
        return this._httpClient.put('update-colours', data);
    }

    public resetColours() {
        return this._httpClient.get<ApiResponse<IColoursSetting>>('get-default-colors');
    }

}
