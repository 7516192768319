import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BaseApiService } from '@api';
import { RouterLinks } from '@constants/routes';
import { AccessHelper } from '@helpers/index';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _baseAPI: BaseApiService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot, _: RouterStateSnapshot
    ): boolean {
        const access = route.data?.access as string[];
        const roles = this._baseAPI.settings.settings.business_manager_old;
        const hasAccess = access ? AccessHelper.checkAccessToView(access, [roles]) : true;
        if (!hasAccess) {
            this._router.navigate([RouterLinks.NOT_FOUND]);
        }
        return hasAccess;
    }
}
