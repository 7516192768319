import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '@services/index';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'cTime'
})
export class CTimePipe implements PipeTransform {
  timeSuffixes: { am: string, pm: string } = { am: 'am', pm: 'pm' };

  constructor(
    private _configSrv: ConfigService,
    private _translateSrv: TranslateService
  ) {
    this._translateSrv.get(['common.am', 'common.pm']).subscribe(response => {
      this.timeSuffixes.am = response['common.am'];
      this.timeSuffixes.pm = response['common.pm'];
    });
  }

  /**
   * transform the time to the format from config service
   *
   * @param value with type `string | number | Date`
   * @param format with type `'24hour' | '12hour'`
   *
   * @return `string`
   */
  transform(
    value: string | number | Date,
    format: '24hour' | '12hour'
      = this._configSrv.timeFormat as '24hour' | '12hour',
  ): any {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      value = this.toSeconds(value);
    } else if (value instanceof Date) {
      value = value.getHours() * 3600 + value.getMinutes() * 60;
    }
    let transformedValue = '';
    if (format === '12hour') {
      transformedValue = this.toTwelveFormat(value);
    } else if (format === '24hour') {
      transformedValue = this.toNormalFormat(value);
    }

    return transformedValue;
  }

  /**
   * helper function transform seconds to toTwelve time format (hh:mm am|pm)
   * 
   * @param value is the time in seconds
   *
   * @return `string`
   */
  toTwelveFormat(value: number) {
    let timeSufix = this.timeSuffixes.am;
    let hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - (hours * 3600)) / 60);

    if (hours >= 12) {
      hours = hours > 12 ? hours - 12 : 12;
      timeSufix = this.timeSuffixes.pm;
    } else {
      hours = hours === 0 ? 12 : hours;
    }

    const hour = ('0' + hours).slice(-2);
    const minute = ('0' + minutes).slice(-2);
    const prefix = timeSufix.toUpperCase();

    return `${hour}:${minute} ${prefix}`;
  }

  /**
   * helper function transform seconds to normal time (hh:mm:ss)
   * 
   * @param value is the time in seconds
   *
   * @return `string`
   */
  toNormalFormat(value: number) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - (hours * 3600)) / 60);

    const hour = ('0' + hours).slice(-2);
    const minute = ('0' + minutes).slice(-2);
    return `${hour}:${minute}`;
  }

  /**
   * helper function transform time string to seconds
   * 
   * @param value is the time in string
   *
   * @return `number`
   */
  toSeconds(value: string) {
    let valueInSeconds = 0;
    value = value.replace(/^.+T/, '');
    const timeArray = value.replace(/[^0-9]/, ':').split(':');
    if (timeArray.length > 0) {
      const hoursInSeconds = +timeArray[0] * 3600;
      const minutesInSeconds = +timeArray[1] * 60;
      valueInSeconds = hoursInSeconds + minutesInSeconds;
    }

    return valueInSeconds;
  }
}
