import { HEX_MATCH, PARSE_RGBA, VALID_HEX_COLOR, VALID_RGBA_COLOR } from '@constants/index';

export class ColorsHelper {

    static isValidHexColor(color: string): boolean {
        return VALID_HEX_COLOR.test(color);
    }

    static isValidRGBAColor(color: string): boolean {
        return VALID_RGBA_COLOR.test(color);
    }

    static generateRandomColor(): string {
        const x = Math.round(0xffffff * Math.random()).toString(16);
        const y = (6 - x.length);
        const z = '000000';
        const z1 = z.substring(0, y);
        return '#' + z1 + x;
    }

    static changeHexToRgba(color: string, alpha: number = 1): string {
        if (VALID_RGBA_COLOR.test(color)) {
            return color;
        }

        let c;
        c = color.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }

        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    };

    static changeRgbaToHex(color: string) {
        if (VALID_HEX_COLOR.test(color)) {
            return color;
        }

        const rgba = color.replace(PARSE_RGBA, '').split(',');
        const hex = `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
        return hex;
    }
}
