import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IWebsiteLanguagesResponse } from '@models/index';


@Injectable({
    providedIn: 'root'
})
export class WebsiteLanguagesService {

    constructor(private _httpClient: HttpClient) { }

    public getWebsiteLanguages() {
        return this._httpClient.get<ApiResponse<IWebsiteLanguagesResponse>>('languages');
    }

    public updateWebsiteLanguages(data: any) {
        return this._httpClient.post('languages', data);
    }
}
