import { ILanguage } from '../languages/language';

export class BannersTranslation {
    title_json: { [key: string]: string };
    subtitle_json: { [key: string]: string };
    button_text_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.title_json = {
                ...this.title_json,
                [language.code]: ''
            };
            this.subtitle_json = {
                ...this.subtitle_json,
                [language.code]: ''
            };
            this.button_text_json= {
                ...this.button_text_json,
                [language.code]: ''
            };
        });
    }
}
