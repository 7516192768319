import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToISO',
    pure: true
})
export class DateToISOPipe implements PipeTransform {
    transform(date: string): string {
        return date?.replace(/\s/, 'T');
    }
}
