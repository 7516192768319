import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IStockGeneral } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class StockGeneralService {

  constructor(private _httpClient: HttpClient) { }

  public getStockGeneral() {
    return this._httpClient.get<ApiResponse<IStockGeneral>>(`stock-general`);
  }

  public updateStockGeneral(body: IStockGeneral) {
    return this._httpClient.put<void>(`stock-general`, { data: body });
  }
}
