import { ILanguage } from '@models/languages/language';

export class ShareItemTranslation {
    subject: { [key: string]: string };
    header_text: { [key: string]: string };
    message_phrase: { [key: string]: string };
    item_details_phrase: { [key: string]: string };
    email_has_been_sent_phrase: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.subject = {
                ...this.subject,
                [language.code]: ''
            };
            this.header_text = {
                ...this.header_text,
                [language.code]: ''
            };
            this.message_phrase = {
                ...this.message_phrase,
                [language.code]: ''
            };
            this.item_details_phrase = {
                ...this.item_details_phrase,
                [language.code]: ''
            };
            this.email_has_been_sent_phrase = {
                ...this.email_has_been_sent_phrase,
                [language.code]: ''
            };
        });
    }
}
