import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IVoucher, IPaginationResponse, IVoucherGetCode, ApiResponse, IVoucherSetting } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class VouchersService {

  constructor(private _httpClient: HttpClient) { }

  public list(page: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<IPaginationResponse<IVoucher[]>>('voucher', { params });
  }


  public get(id: string) {
    return this._httpClient.get<ApiResponse<IVoucher>>(`voucher/${id}/edit`);
  }

  public getUniqueCode() {
    return this._httpClient.get<IVoucherGetCode>('get-voucher-code');
  }

  public getSetting() {
    return this._httpClient.get<IVoucherSetting>(`get-voucher-setting`);
  }

  public addVoucherSetting(data: boolean) {
    return this._httpClient.post(`update-voucher-setting`, {voucher_allow_on_sale_price: String(Number(data))});
  }

  public add(data: IVoucher) {
    return this._httpClient.post<void>('voucher', data);
  }

  public edit(data: IVoucher) {
    return this._httpClient.put<void>(`voucher/${data.voucher_id}`, data);
  }

  public delete(id: string) {
    return this._httpClient.delete<void>(`voucher/${id}`);
  }
}
