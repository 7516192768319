import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ColorPickerComponent,
      multi: true,
    },
  ],
})
export class ColorPickerComponent implements OnInit {
  @Input() label: string;
  @Input() reversed: boolean;
  @Input() showPreview: boolean;
  @Input() overflow: string = 'hidden';
  @Input() bold: boolean = true;
  @Input() showTooltip: boolean = true;
  @Input() textMaxWidth: string = 'initial';

  public control: FormControl = new FormControl();
  public toggle: boolean;

  constructor() { }

  ngOnInit(): void {
    this._subscribeToValueChange();
  }

  private _subscribeToValueChange(): void {
    this.control.valueChanges.subscribe((v) => {
      this._propagateChange(v);
    });
  }

  private _propagateChange = (_: any) => { };

  public writeValue(value: string): void {
    this.control.setValue(value);
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
}
