import { ILanguage } from '../languages/language';

export class PressTranslation {
    press_media_html_json: { [key: string]: string };
    press_media_title_json: { [key: string]: string };
    tag_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
    description_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.press_media_html_json = {
                ...this.press_media_html_json,
                [language.code]: ''
            };
            this.press_media_title_json = {
                ...this.press_media_title_json,
                [language.code]: ''
            };
            this.tag_json = {
                ...this.tag_json,
                [language.code]: ''
            };
            this.keywords_json = {
                ...this.keywords_json,
                [language.code]: ''
            };
            this.description_json = {
                ...this.description_json,
                [language.code]: ''
            };
        });
    }
}
