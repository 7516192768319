import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ISocialMediaRequest } from '@models/index';


@Injectable({
    providedIn: 'root'
})
export class SocialMediaService {

  constructor(private _httpClient: HttpClient) { }

  getSocialMedia() {
    return this._httpClient.get<ISocialMediaRequest>(`get-social-media-links`);
  }


  updateSocialMedia(post: ISocialMediaRequest) {
    return this._httpClient.put(`update-social-media-links`, {data: post});
  }
}
