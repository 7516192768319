import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    ApiResponse,
    ICategoryBox,
    ICategoryBoxesCategory,
    ICategoryBoxesWithWebSettings,
    IChangeCategoryBoxesOrder,
    IUpdateCategoryBoxesWebSettings
} from '@models/index';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CategoryBoxesService {

    constructor(private _httpClient: HttpClient) { }

    public getCategoryBoxesWithWebSettings() {
        return this._httpClient.get<ApiResponse<ICategoryBoxesWithWebSettings>>('newcategory-box')
            .pipe(
                map((boxes) => {
                    boxes.data.category_boxes.forEach(box => box.img_url = box.images[0]?.image_url);
                    return boxes;
                })
            );
    }

    public getCategories() {
        return this._httpClient.get<ICategoryBoxesCategory>('category-box-get-data');
    }

    public updateWebSettings(webSettings: IUpdateCategoryBoxesWebSettings) {
        return this._httpClient.post<void>('newcategory-box-settings', webSettings);
    }

    public add(categoryBox: FormData) {
        return this._httpClient.post<ICategoryBox>('newcategory-box', categoryBox);
    }

    public update(categoryBox: FormData, id: number) {
        return this._httpClient.post<void>(`newcategory-box/${id}`, categoryBox);
    }

    public delete(id: number) {
        return this._httpClient.delete<void>(`newcategory-box/${id}`);
    }

    public changeVisibility(visible: number) {
        return this._httpClient.post('newcategory-box-visible', { visible });
    }

    public changeOrders(data: IChangeCategoryBoxesOrder) {
        return this._httpClient.post<void>('newcategory-order', data);
    }
}
