import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse,
    IBanner,
    IChangeBannersOrder
} from '@models/index';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BannersService {

    constructor(private _httpClient: HttpClient) { }

    public add(data: FormData) {
        return this._httpClient.post<ApiResponse<IBanner>>('bannernew', data)
            .pipe(
                map((banner) => {
                    banner.data.img_url = banner.data.images[0]?.image_url;
                    return banner;
                })
            );
    }

    public list() {
        return this._httpClient.get<ApiResponse<IBanner[]>>('bannernew')
            .pipe(
                map((banners) => {
                    banners.data.forEach(banner => banner.img_url = banner.images[0]?.image_url);
                    return banners;
                })
            );
    }

    public delete(id: number) {
        return this._httpClient.delete(`bannernew/${id}`);
    }

    public update(banner: IBanner) {
        return this._httpClient.put(`bannernew/${banner.id}`, banner);
    }

    public changeVisibility(id: number) {
        return this._httpClient.post<void>(`bannernew-visible/${id}`, {});
    }

    public changeOrders(data: IChangeBannersOrder) {
        return this._httpClient.post<void>('bannernew-order', data);
    }

    public sendTime(miliseconds: number) {
      return this._httpClient.post('banner-transition-time', {transition_time: miliseconds});
    }

    public getTime() {
      return this._httpClient.get<{transition_time: number}>('banner-transition-time');
    }
}
