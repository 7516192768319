import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UrlChecker } from '@helpers/index';

export class UrlValidator {

  /**
   * validation function
   * static method checks the youtube url validity
   *
   * @param control type is a `AbstractControl`
   *
   * @return `ValidationErrors` OR `null`
   */
  static youtube(control: AbstractControl): ValidationErrors | null {

    if (!control.value) { return null; }

    if (!UrlChecker.isYoutube(control.value)) {
      return { invalidYoutubeUrl: true };
    }

    return null;
  }

  /**
   * validation function
   * static method checks the vimeo url validity
   *
   * @param control type is a `AbstractControl`
   *
   * @return `ValidationErrors` OR `null`
   */
  static vimeo(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }

    if (!UrlChecker.isVimeo(control.value)) {
      return { invalidVimeoUrl: true };
    }

    return null;
  }

  static vimeoOrYoutube(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }

    if (!UrlChecker.isVimeo(control.value) && !UrlChecker.isYoutube(control.value)) {
      return { invalidVimeoOrYoutubeUrl: true };
    }

    return null;
  }



  static isValidUrl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }

    if (!UrlChecker.isValidUrl(control.value)) {
      return { invalidUrl: true };
    }

    return null;
  }

  static matterport (control: AbstractControl): ValidationErrors | null {
    if (!control.value) { return null; }

    if (control.value.trim()) {
      // const string = 'https:\/\/my.matterport.com\/show\/?m=';
      const string = '^https://my\.matterport\.com/show/\\?m=';
      const reg = new RegExp(string);

      if (!reg.test(control.value)) {

        return {invalidMatterportURL: true};
      }
    }

    return null;
  }
}
