import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse, ICatalogue, IPaginationResponse, IStockItem
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class CataloguesService {

    constructor(private _httpClient: HttpClient) { }


    public list(page: number) {
        const params = new HttpParams().set('page', page.toString());
        return this._httpClient.get<ApiResponse<IPaginationResponse<ICatalogue[]>>>('catalogs', { params });
    }

    public get(id: string) {
        return this._httpClient.get<ApiResponse<ICatalogue>>(`catalogs/${id}`);
    }

    public add(data: FormData) {
        return this._httpClient.post('catalogs', data);
    }

    public update(catalogue: FormData, id: string) {
        return this._httpClient.post(`catalogs/${id}`, catalogue);
    }

    public delete(id: string) {
        return this._httpClient.delete(`catalogs/${id}`);
    }

    public changeVisibility(data: FormData) {
        return this._httpClient.post(`catalogs/${data.get('id')}/visibility`, data);
    }

    public stockSearch(term: string, page: number, hasImage: boolean) {
        const params = new HttpParams().set('s', term).set('page', page.toString()).set('has_image', Number(hasImage).toString());
        return this._httpClient.get<ApiResponse<IPaginationResponse<IStockItem[]>>>('stock/search', { params });
    }

}
