import { ILanguage } from '../languages/language';

export class PagesTranslation {
    page_title_artists: { [key: string]: string };
    page_title_archive: { [key: string]: string };
    page_title_blog: { [key: string]: string };
    page_title_featured: { [key: string]: string };
    page_title_events: { [key: string]: string };
    page_title_latest: { [key: string]: string };
    page_title_newsletter_subtitle_footer: { [key: string]: string };
    page_title_newsletter_subtitle: { [key: string]: string };
    page_title_stock: { [key: string]: string };
    page_title_newsletter_title: { [key: string]: string };
    page_title_view_all: { [key: string]: string };
    page_title_press: { [key: string]: string };
    page_title_catalogues: { [key: string]: string };
    page_title_exhibitions: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.page_title_artists = {
                ...this.page_title_artists,
                [language.code]: ''
            };
            this.page_title_archive = {
                ...this.page_title_archive,
                [language.code]: ''
            };
            this.page_title_blog = {
                ...this.page_title_blog,
                [language.code]: ''
            };
            this.page_title_featured = {
                ...this.page_title_featured,
                [language.code]: ''
            };
            this.page_title_events = {
                ...this.page_title_events,
                [language.code]: ''
            };
            this.page_title_latest = {
                ...this.page_title_latest,
                [language.code]: ''
            };
            this.page_title_newsletter_subtitle_footer = {
                ...this.page_title_newsletter_subtitle_footer,
                [language.code]: ''
            };
            this.page_title_newsletter_subtitle = {
                ...this.page_title_newsletter_subtitle,
                [language.code]: ''
            };
            this.page_title_stock = {
                ...this.page_title_stock,
                [language.code]: ''
            };
            this.page_title_newsletter_title = {
                ...this.page_title_newsletter_title,
                [language.code]: ''
            };
            this.page_title_view_all = {
                ...this.page_title_view_all,
                [language.code]: ''
            };
            this.page_title_press = {
                ...this.page_title_press,
                [language.code]: ''
            };
            this.page_title_catalogues = {
                ...this.page_title_catalogues,
                [language.code]: ''
            };
            this.page_title_exhibitions = {
                ...this.page_title_exhibitions,
                [language.code]: ''
            };
        });
    }
}
