import { SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UrlValidator } from '@validators/url.validator';

@Component({
  selector: 'app-matterport-modal',
  templateUrl: './matterport-modal.component.html',
  styleUrls: ['./matterport-modal.component.scss']
})
export class MatterportModalComponent implements OnInit {

  public matterport: FormControl = new FormControl('', [UrlValidator.matterport]);
  public url: string;

  constructor() { }

  ngOnInit(): void {
    this.matterport.setValue(this.url);
  }

}
