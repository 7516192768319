import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ILoginRequest, ILoginResponse } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private _httpClient: HttpClient) { }

    public signIn(credentials: ILoginRequest) {
        const params = new HttpParams().set('authorization', 'false');
        return this._httpClient.post<ILoginResponse>('dashboard-login', credentials, { params });
    }
}

