import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUpdateCompanyLogos } from '@models/index';
import { ICompanyLogosResponse } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class CompanyLogoService {

    constructor(
        private _httpClient: HttpClient
    ) { }

    public list() {
        return this._httpClient.get<ICompanyLogosResponse>('company-logo');
    }

    public update(data: IUpdateCompanyLogos) {
        return this._httpClient.post<void>('company-logo', data);
    }
}
