import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IAddintegrationItem, IIntegrations, ITwitterLogin } from '@models/index';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  private _twitterObtainTokenUrl: string = 'https://api.twitter.com/oauth/request_token';

  constructor(private _httpClient: HttpClient) { }

  public get() {
    return this._httpClient.get<ApiResponse<IIntegrations>>('integration-get');
  }

  public post(data: IAddintegrationItem) {
    // const headers = new HttpHeaders().set('skip', 'true');
    return this._httpClient.post<any>('integration-login', data);
  }

  public delete(name: string) {
    return this._httpClient.delete(`integration-delete-user/${name}`);
  }

  public twitterLogin(body: ITwitterLogin): Observable<any> {
    const headers = new HttpHeaders().set('skip', 'true').set('Authorization', 'OAuth oauth_consumer_key=' + body.oauth_consumer_key);
    return this._httpClient.post(this._twitterObtainTokenUrl, body, { headers });
  }

}
