import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IMatterports } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class MatterportService {

  constructor(private _httpClient: HttpClient) { }

  public get() {
    return this._httpClient.get<ApiResponse<IMatterports[]>>('matterport-links');
  }

  public post(matterports: IMatterports[]) {
    return this._httpClient.post('matterport-links', {data: matterports});
  }

  public update(matterport: IMatterports, id: string) {
    return this._httpClient.put(`matterport-links/${id}`, matterport);
  }

  public delete(id: number) {
    return this._httpClient.delete(`matterport-links/${id}`);
  }

}
