import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdminSettings } from '@models/index';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminSettingsService {

    constructor(private _httpClient: HttpClient) { }

    public getAdminSettings(): Observable<IAdminSettings> {
        return this._httpClient.get<IAdminSettings>('admin-settings');
    }

    public updateAdminSetting(body: IAdminSettings): Observable<any> {
        return this._httpClient.put('admin-settings', body);
    }
}
