import { IIntegrationItem } from '@models/index';

export const ART_INTEGRATIONS: IIntegrationItem[] = [
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/affordable_art_fair.png',
        label: 'Affortable Art Fair',
        key: 'affordable_art_fair'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/artnet.png',
        label: 'Artnet',
        key: 'artnet'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/artsy.png',
        label: 'Artsy',
        key: 'artsy'
    }
];

export const ANTIQUES_INTEGRATIONS: IIntegrationItem[] = [
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/1stdibs.png',
        label: 'First Dibs',
        key: '1stdibs'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/2covet.png',
        label: 'TwoCovet',
        key: '2covet'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/selling_antiques.png',
        label: 'Selling Antiques',
        key: 'selling_antiques'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/bada.png',
        label: 'Bada',
        key: 'bada'
    },
    // {
    //     url: '',
    //     imageUrl: 'assets/images/Stock/integrations/palada.png',
    //     label: 'Palada'
    // }
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/lapada.png',
        label: 'Lapada',
        key: 'lapada'
    },
    {
      url: '',
      imageUrl: 'assets/images/Stock/integrations/etsy.png',
      label: 'Etsy',
      key: 'etsy'
  }
];

export const ECOMMERCE_INTEGRATIONS: IIntegrationItem[] = [
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/ebay.png',
        label: 'Ebay',
        key: 'ebay'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/amazon.png',
        label: 'Amazon',
        key: 'amazon'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/google_shop.png',
        label: 'Google shop',
        key: 'google_shop'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/facebook-marketplace.png',
        label: 'Facebook marketplace',
        key: 'facebook-marketplace'
    },
];

export const SOCIAL_INTEGRATIONS: IIntegrationItem[] = [
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/facebook.png',
        label: 'Facebook post',
        key: 'facebook'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/instagram.png',
        label: 'Instagram post',
        key: 'instagram'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/pinterest.png',
        label: 'Pinterest',
        key: 'pinterest'
    },
    {
        url: '',
        imageUrl: 'assets/images/Stock/integrations/twitter.png',
        label: 'Twitter',
        key: 'twitter'
    }
];
