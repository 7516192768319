import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ISettings } from '@models/index';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public settings: ISettings;

    constructor(private _httpClient: HttpClient) { }

    public getSettings() {
        return this._httpClient.get<ApiResponse<ISettings>>('get-settings')
            .pipe(
                map(({ data }) => {
                    this.settings = data;
                    return data;
                })
            );
    }
}
