import { FormArray, FormBuilder, ValidatorFn } from '@angular/forms';
import { ILanguage } from '@models/index';

export class MultilingualHelper {
    static validateAllTabs<T>(
        controls: { [key: string]: any },
        builder: FormBuilder,
        allLanguges: ILanguage[],
        multilingualData: T,
        currentLanguageKey: string,
        includesValidators: {
            [key: string]: {
                default: ValidatorFn[],
                changed: ValidatorFn[]
            }
        }
    ): ILanguage[] {
        const multilingualDataKeys = Object.keys(multilingualData);
        const [defaultLanguageCode] = allLanguges;
        allLanguges = allLanguges.map(language => {
            const group = builder.group(controls);
            for (const key in includesValidators) {
                const element = includesValidators[key];
                if (Array.isArray(multilingualData[key])) {
                    multilingualData[key].forEach((_, index) => {
                        (group.get(key) as FormArray)
                            .at(index).get(key.split('_json')[0]).setValidators(
                                defaultLanguageCode.code === language.code ? element.changed : element.default
                            );
                    });
                } else {
                    group.get(key).setValidators(
                        defaultLanguageCode.code === language.code ? element.changed : element.default
                    );
                }
            }
            multilingualDataKeys.forEach(key => {
                if (Array.isArray(multilingualData[key])) {
                    multilingualData[key].forEach((_, index) => {
                        (group.get(key) as FormArray)
                            .at(index).get(key.split('_json')[0]).setValue(multilingualData[key][index][language.code]);
                    });
                } else {
                    group.get(key).setValue(multilingualData[key][language.code]);
                }
            });
            group.updateValueAndValidity();
            return {
                ...language,
                invalid: currentLanguageKey === language.code ? false : group.invalid,
                isFullyFilled: Object.keys(group.controls).reduce((acc, key) => {
                    // This part is hardcoded for Category boxes
                    // TODO Needs to be fixed in future
                    if (Array.isArray(group.controls[key].value)) {
                        group.controls[key].value.forEach(element => {
                            if (!element.title) {
                                acc = false;
                            }
                        });
                        return acc;
                    }
                    //
                    if (!group.controls[key].value) {
                        return acc = false;
                    }
                    return acc;
                }, true),
            };
        });
        return allLanguges;
    }
}
