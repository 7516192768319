import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiKey } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ApiKeysService {

    constructor(private _httpClient: HttpClient) { }

    public getApiKeys() {
        return this._httpClient.get<IApiKey[]>('api-keys');
    }

    public getApiKey(id: string) {
        return this._httpClient.get<IApiKey>(`api-keys/${id}`);
    }

    public createApiKey(body: IApiKey) {
        return this._httpClient.post<void>('api-keys', body);
    }

    public updateApiKey(id: string, body: IApiKey) {
        return this._httpClient.put<void>(`api-keys/${id}`, body);
    }

    public deleteApiKey(id: string) {
        return this._httpClient.delete<void>(`api-keys/${id}`);
    }
}
