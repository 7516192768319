import { IMatterPort } from "@models/utils/matterport";

export enum EEventStatuses {
    EDITING = 'editing',
    PUBLISHED = 'published'
}

export interface IDeleteEventRequest {
    id: string;
}

export interface IChangeEventVisibilityRequest {
    id: string;
    visible: number;
}

export interface IEventFile {
    alt_text: string;
    archived: number;
    image_id: string;
    image_name: string;
    image_type: string;
    image_url: string;
    image_visible: string;
    list_url: string;
    main_image: 1 | 0;
    parent_id: string;
    permalink: string;
    sync_key: string;
    sync_last: string;
    thumb_url: string;
    button_text: string;
    additional_text: string;
}

export interface IEventsResponse {
    current_page: number;
    data: IEvent[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

export interface IEvent {
    archived: number;
    event_end_date: string;
    event_html: string;
    event_html_json: { [key: string]: string };
    event_id: string;
    event_name: string;
    event_name_json: { [key: string]: string };
    event_slug: string;
    event_start_date: string;
    image_name: string;
    files: IEventFile[];
    sync_last: string;
    visible: number;
    matterport_links: IMatterPort[];
    keywords_json: { [key: string]: string };
    description_json: { [key: string]: string };
}

export interface IEventCreate {
    event_id?: string;
    event_name: { [key: string]: string };
    event_start_date: string;
    event_end_date: string;
    image_name: string;
    event_html: { [key: string]: string };
    visible: number;
    event_pdfs?: {
        image_url: string;
        button_text: string;
        additional_text: string;
    }[];
    matterport_ids: string[],
    keywords: { [key: string]: string };
    description: { [key: string]: string };
}
