import { Injectable } from '@angular/core';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { ApiKeysService } from '../api-keys/api-keys.service';
import { ArtistTypesService } from '../artist-types/artist-types.service';
import { ArtistsService } from '../artists/artists.service';
import { AssociationLogoService } from '../association-logo/association-logo.service';
import { AuthService } from '../auth/auth.service';
import { BannersService } from '../banners/banners.service';
import { BlogsService } from '../blogs/blogs.service';
import { CataloguesService } from '../catalogue/catalogues.service';
import { CategoriesService } from '../categories/categories.service';
import { CategoryBoxesService } from '../category-boxes/category-boxes.service';
import { ChartsService } from '../charts/charts.service';
import { ColoursService } from '../colours/colours.service';
import { CompanyLogoService } from '../company-logo/company-logo.service';
import { ContactsService } from '../contacts/contacts.service';
import { CustomPagesService } from '../custom-pages/custom-pages.service';
import { EcommerceService } from '../ecommerce/ecommerce.service';
import { EmailTemplatesService } from '../email-templates/email-templates.service';
import { EnquiryService } from '../enquiry/enquiry.service';
import { EventsService } from '../events/events.service';
import { ExhibitionsService } from '../exhibitions/exhibitions.service';
import { GeneralService } from '../general/general.service';
import { GoogleKeysService } from '../google-keys/google-keys.service';
import { IntegrationItemService } from '../integration-item/integration-item.service';
import { IntegrationsStockService } from '../integration-stock/integrationsStock.service';
import { IntegrationsService } from '../integrations/integration.service';
import { LocationsService } from '../locations/locations.service';
import { MenuOrderService } from '../menu-order/menu-order.service';
import { MetaService } from '../meta-description/meta.service';
import { NewsletterService } from '../newsletter/newsletter.service';
import { OrdersService } from '../orders/orders.service';
import { PagesService } from '../pages-menu/pages.service';
import { PaymentService } from '../payments/payments.service';
import { PressService } from '../press/press.service';
import { SettingsService } from '../settings/settings.service';
import { ShippingClassesService } from '../shipping-classes/shipping-classes.service';
import { ShippingOptionsService } from '../shipping-options/shipping-options.service';
import { SocialMediaService } from '../social-media/social-media.service';
import { StandardFeaturesService } from '../standard-features/standard-features.service';
import { StatusPageService } from '../status-page/status-page.service';
import { StockService } from '../stocks/stock.service';
import { TaxSettingsService } from '../tax-settings/tax-settings.service';
import { TradeRequestService } from '../trade-request/trade-request.service';
import { UtilsService } from '../utils/utils.service';
import { VouchersService } from '../vouchers/vouchers.service';
import { WebsiteLanguagesService } from '../website-languages/website-languages.service';
import { WebsiteStaticTextsService } from '../website-static-texts/website-static-texts.service';
import { WidgetsService } from '../widgets/widgets.service';
import { StockGeneralService } from '../stock-general/stock-general.service';
import {AttributesService} from '../attributes/attributes.service';
import {BulkUploadService} from '../bulk-upload/bulk-upload.service';
import { MatterportService } from '../matterport/matterport.service';
import { SortingOptionsService } from '../sorting-options/sorting-options.service';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {

  constructor(
    public utils: UtilsService,
    public auth: AuthService,
    public banners: BannersService,
    public blogs: BlogsService,
    public events: EventsService,
    public stock: StockService,
    public location: LocationsService,
    public categories: CategoriesService,
    public artistTypes: ArtistTypesService,
    public artists: ArtistsService,
    public shippingOptions: ShippingOptionsService,
    public vouchers: VouchersService,
    public shippingClasses: ShippingClassesService,
    public customPages: CustomPagesService,
    public socialMedia: SocialMediaService,
    public associationLogo: AssociationLogoService,
    public categoryBoxes: CategoryBoxesService,
    public companyLogo: CompanyLogoService,
    public pages: PagesService,
    public googleKeys: GoogleKeysService,
    public colours: ColoursService,
    public meta: MetaService,
    public ecommerce: EcommerceService,
    public general: GeneralService,
    public press: PressService,
    public stockGeneral: StockGeneralService,
    public standardFeatures: StandardFeaturesService,
    public orders: OrdersService,
    public enquiry: EnquiryService,
    public catalogue: CataloguesService,
    public settings: SettingsService,
    public integrations: IntegrationsService,
    public integrationItem: IntegrationItemService,
    public integrationsStocK: IntegrationsStockService,
    public statusPage: StatusPageService,
    public adminSettings: AdminSettingsService,
    public websiteLanguages: WebsiteLanguagesService,
    public exhibitions: ExhibitionsService,
    public websiteStaticTexts: WebsiteStaticTextsService,
    public taxSettings: TaxSettingsService,
    public menuOrder: MenuOrderService,
    public newsletter: NewsletterService,
    public widgets: WidgetsService,
    public contacts: ContactsService,
    public tradeRequest: TradeRequestService,
    public payments: PaymentService,
    public emailTemplates: EmailTemplatesService,
    public apiKeys: ApiKeysService,
    public charts: ChartsService,
    public attributes: AttributesService,
    public bulk_upload: BulkUploadService,
    public matterport: MatterportService,
    public sortingOptions: SortingOptionsService
  ) { }

}
