import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class EnquiryService {

  constructor(private _httpClient: HttpClient) { }
  public getAll(page: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<any>(`enquiries`, { params });
  }

  public getDetail(id: string) {
    return this._httpClient.get<any>(`enquiries/${id}`);
  }

  public delete(id: string) {
    return this._httpClient.delete(`enquiries/${id}`);
  }

  public search(data: string, page: number) {
    const params = new HttpParams().append('page', String(page)).append('s', data);
    return this._httpClient.get('enquiries-search', { params });
  }

}
