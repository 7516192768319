import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISocialMediaRequest } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private _httpClient: HttpClient) { }

  getMeta() {
    return this._httpClient.get<any>(`get-meta-data`);
  }


  updateMeta(post: ISocialMediaRequest) {
    return this._httpClient.put(`update-meta-data`, { data: post });
  }
}
