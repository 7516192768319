import { ILanguage } from '@models/languages/language';

export class TaxSettingsTranslation {
    declaration: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.declaration = {
                ...this.declaration,
                [language.code]: ''
            };

        });
    }
}
