import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BaseApiService } from '@api';
import { RouterLinks } from '@constants/index';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _baseAPI: BaseApiService,
    ) { }

    canActivate(): Observable<boolean> | boolean {
        return this._baseAPI.settings.getSettings()
            .pipe(
                map(() => {
                    return true;
                }),
                catchError(() => {
                    this._router.navigate([RouterLinks.SIGN_IN]);
                    return of(false);
                })
            );
    }
}
