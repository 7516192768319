export const UPDATE_BANNERS_INTERVAL = 5000;
export const BANNER_IMAGE_MAXIMUM_SIZE = 1000000;
export const BANNER_IMAGE_MINIMUM_SIZE = 1;
export const BANNER_IMAGE_COMRESS_MIN = 1000000;
export const BANNER_IMAGE_MAX_WIDTH = 3000;
export const YOUTUBE_EMBED_URL = 'https://youtube.com/embed/';
export const VIMEO_URL = 'https://player.vimeo.com/video/';
export const BANNER_VIDEO_MAX_SIZE = 26214400;
export const IMAGE_FOCUS_POSITIONS: { value: string, label: string }[] = [
    {
        value: 'left-top',
        label: 'Top left'
    },
    {
        value: 'right-top',
        label: 'Top right'
    },
    {
        value: 'center',
        label: 'Center'
    },
    {
        value: 'left-center',
        label: 'Center left'
    },
    {
        value: 'right-center',
        label: 'Center right'
    },
    {
        value: 'left-bottom',
        label: 'Bottom left'
    },
    {
        value: 'right-bottom',
        label: 'Bottom right'
    },
]
