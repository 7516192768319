import { ILanguage } from '../languages/language';

export class ShippingClassesTranslation {
    shipping_class_poa_message_website: { [key: string]: string };
    shipping_class_poa_message_order: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach((language) => {
            this.shipping_class_poa_message_website = {
                ...this.shipping_class_poa_message_website,
                [language.code]: ''
            };
            this.shipping_class_poa_message_order = {
                ...this.shipping_class_poa_message_order,
                [language.code]: ''
            };
        });

    }
}
