import { ILanguage } from '@models/languages/language';

export class LocationsTranslation {
    message_json: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.message_json = {
                ...this.message_json,
                [language.code]: ''
            };
        });
    }
}
