export enum EListViewType {
    STYLE_ONE = '1',
    STYLE_TWO = '2',
    STYLE_THREE = '3',
    STYLE_FOUR = '4',
    STYLE_FIVE = '5',
    STYLE_SIX = '6',
    STYLE_SEVEN = '7',
    STYLE_EIGHT = '8',
    STYLE_NINE = '9'
}
