import { ILanguage } from '@models/languages/language';

export class MetasTranslation {
    site_description: { [key: string]: string };
    site_keywords: { [key: string]: string };
    page_description_featured_items: { [key: string]: string };
    page_keywords_featured_items: { [key: string]: string };
    page_description_latest_arrivals: { [key: string]: string };
    page_keywords_latest_arrivals: { [key: string]: string };
    page_keywords_view_all_items: { [key: string]: string };
    page_description_view_all_items: { [key: string]: string };
    page_keywords_archive: { [key: string]: string };
    page_description_archive: { [key: string]: string };
    page_description_artists_list: { [key: string]: string };
    page_keywords_artists_list: { [key: string]: string };
    page_description_blogs: { [key: string]: string };
    page_keywords_blogs: { [key: string]: string };
    page_events: { [key: string]: string };
    page_keywords_events: { [key: string]: string };
    page_description_contact: { [key: string]: string };
    page_keywords_contact: { [key: string]: string };
    page_description_about: { [key: string]: string };
    page_keywords_about: { [key: string]: string };
    page_description_faq: { [key: string]: string };
    page_keywords_faq: { [key: string]: string };
    page_description_exhibitions: { [key: string]: string };
    page_keywords_exhibitions: { [key: string]: string };
    page_description_delivery_returns: { [key: string]: string };
    page_keywords_delivery_returns: { [key: string]: string };
    page_description_privacy_policy: { [key: string]: string };
    page_keywords_privacy_policy: { [key: string]: string };
    page_description_terms_conditions: { [key: string]: string };
    page_keywords_terms_conditions: { [key: string]: string };
    page_description_catalogue: { [key: string]: string };
    page_keywords_catalogue: { [key: string]: string };
    page_description_press: { [key: string]: string };
    page_keywords_press: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.site_description = {
                ...this.site_description,
                [language.code]: ''
            };
            this.site_keywords = {
                ...this.site_keywords,
                [language.code]: ''
            };
            this.page_description_featured_items = {
                ...this.page_description_featured_items,
                [language.code]: ''
            };
            this.page_keywords_featured_items = {
                ...this.page_keywords_featured_items,
                [language.code]: ''
            };
            this.page_description_latest_arrivals = {
                ...this.page_description_latest_arrivals,
                [language.code]: ''
            };
            this.page_keywords_latest_arrivals = {
                ...this.page_keywords_latest_arrivals,
                [language.code]: ''
            };
            this.page_keywords_view_all_items = {
                ...this.page_keywords_view_all_items,
                [language.code]: ''
            };
            this.page_description_view_all_items = {
                ...this.page_description_view_all_items,
                [language.code]: ''
            };
            this.page_keywords_archive = {
                ...this.page_keywords_archive,
                [language.code]: ''
            };
            this.page_description_artists_list = {
                ...this.page_description_artists_list,
                [language.code]: ''
            };
            this.page_description_blogs = {
                ...this.page_description_blogs,
                [language.code]: ''
            };
            this.page_keywords_blogs = {
                ...this.page_keywords_blogs,
                [language.code]: ''
            };
            this.page_events = {
                ...this.page_events,
                [language.code]: ''
            };
            this.page_keywords_events = {
                ...this.page_keywords_events,
                [language.code]: ''
            };
            this.page_description_contact = {
                ...this.page_description_contact,
                [language.code]: ''
            };
            this.page_keywords_contact = {
                ...this.page_keywords_contact,
                [language.code]: ''
            };
            this.page_description_about = {
                ...this.page_description_about,
                [language.code]: ''
            };
            this.page_keywords_about = {
                ...this.page_keywords_about,
                [language.code]: ''
            };
            this.page_description_faq = {
                ...this.page_description_faq,
                [language.code]: ''
            };
            this.page_keywords_faq = {
                ...this.page_keywords_faq,
                [language.code]: ''
            };
            this.page_description_exhibitions = {
                ...this.page_description_exhibitions,
                [language.code]: ''
            };
            this.page_keywords_exhibitions = {
                ...this.page_keywords_exhibitions,
                [language.code]: ''
            };
            this.page_description_delivery_returns = {
                ...this.page_description_delivery_returns,
                [language.code]: ''
            };
            this.page_keywords_delivery_returns = {
                ...this.page_keywords_delivery_returns,
                [language.code]: ''
            };
            this.page_description_privacy_policy = {
                ...this.page_description_privacy_policy,
                [language.code]: ''
            };
            this.page_keywords_privacy_policy = {
                ...this.page_keywords_privacy_policy,
                [language.code]: ''
            };
            this.page_description_terms_conditions = {
                ...this.page_description_terms_conditions,
                [language.code]: ''
            };
            this.page_keywords_terms_conditions = {
                ...this.page_keywords_terms_conditions,
                [language.code]: ''
            };
            this.page_description_catalogue = {
                ...this.page_description_catalogue,
                [language.code]: ''
            };
            this.page_keywords_catalogue = {
                ...this.page_keywords_catalogue,
                [language.code]: ''
            };
            this.page_description_press = {
                ...this.page_description_press,
                [language.code]: ''
            };
            this.page_keywords_press = {
                ...this.page_keywords_press,
                [language.code]: ''
            };
        });
    }
}
