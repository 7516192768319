import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, IMenuOrdersResponse } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class MenuOrderService {

  constructor(private _httpClient: HttpClient) { }

  public get() {
    return this._httpClient.get<ApiResponse<IMenuOrdersResponse>>('menu');
  }

  public post(body: IMenuOrdersResponse) {
    return this._httpClient.post<IMenuOrdersResponse>(`menu`, body);
  }

}
