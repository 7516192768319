export enum EContactAnalyticType {
    DAILY = 'daily',
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    TOTAL = 'total',
    LAST_MONTH = 'last_month',
    LAST_WEEK = 'last_week',
    LAST_YEAR = 'last_year',
    YEARLY = 'yearly',
    YESTERDAY = 'yesterday'
}
