export const RequiredStockFields = [
  'stock_number',
  'title',
  'website_visible',
  'price_visible',
  'price_discounted_visible',
  'available_to_buy',
  'featured',
  'reserved',
  'private_view',
  'shipping_class_id',
  'price_retail',
  'image_url_01',
  'dimensions_size_unit',
  'dimensions_weight_unit',
];

export const DimensionsSizeFields = [
  'mm',
  'cm'
];

export const DimensionsWeightFields = [
  'g',
  'kg',
  'Ib'
];


export const maxStockListValue = 5001;

