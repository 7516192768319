export class AccessHelper {

    static checkAccessToView(accessRoles: string[], roles: string[]): boolean {
        let hasAccess = false;
        for (const item of accessRoles) {
            if (roles.includes(item)) {
                hasAccess = true;
                break;
            }
        }
        return hasAccess;
    }
}
