import { ILanguage } from '../languages/language';

export class EventTranslation {
    event_name_json: { [key: string]: string };
    event_html_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
    description_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.event_name_json = {
                ...this.event_name_json,
                [language.code]: ''
            };
            this.event_html_json = {
                ...this.event_html_json,
                [language.code]: ''
            };
            this.keywords_json = {
                ...this.keywords_json,
                [language.code]: ''
            };
            this.description_json = {
                ...this.description_json,
                [language.code]: ''
            };
        });
    }
}
