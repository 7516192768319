import { IListViewElement } from '@models/index';

export const LIST_VIEW_ELEMENTS: IListViewElement[] = [
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_TITLE',
        key: 'title',
        visible: 1
    },
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_ARTIST_NAME',
        key: 'artist_name',
        visible: 1
    },
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_PRICE',
        key: 'price',
        visible: 1
    },
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_HEIGHT',
        key: 'height',
        visible: 1
    },
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_WIDTH',
        key: 'width',
        visible: 1
    },
    {
        label: 'WIDGETS.LIST_VIEW_ELEMENT_REF',
        key: 'ref',
        visible: 1
    }
];
