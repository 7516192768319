import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAssociationLogo } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class AssociationLogoService {

  constructor(private _httpClient: HttpClient) { }

  public get() {
    return this._httpClient.get<IAssociationLogo[]>(`get-association-logos`);
  }

  public update(data: FormData) {
    return this._httpClient.post(`update-association-logos`, data);
  }
}
