import { environment } from '@env';
export const FROALA_OPTIONS = {
    placeholder: 'Type your blog',
    toolbarButtons: {
        moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        },
        moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR']
        },
        moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help', 'modal']
        },
    },
    attribution: false,
    key: 'UBB7jF4A3H4F4F3A7aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6C5C2C4D3H3I3F3==',
    imageUploadURL: `${environment.API_URL}froala-image-upload`,
    imageUploadMethod: 'POST',
    events: {
      initialized: function() {
        console.log(this, 'initialized -> this');
        console.log(this && this.$el, 'initialized -> this && this.$el');
      },
    }
};

export const FROALA_OPTIONS_ABOUT = {
    toolbarButtons: {
        moreText: {
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
        },
        moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        moreRich: {
            buttons: ['insertLink', 'fontAwesome', 'specialCharacters']
        },
        moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'selectAll', 'html', 'help', 'modal']
        },
    },
    attribution: false,
    key: 'UBB7jF4A3H4F4F3A7aIVLEABVAYFKc1Ce1MYGD1c1NYVMiB3B9B6C5C2C4D3H3I3F3==',
    events: {
      initialized: function() {
        console.log(this, 'initialized -> this');
        console.log(this && this.$el, 'initialized ->  this && this.$el');
      },
    }
};
