import { DEFAULT_DELAY } from '@constants/index';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';

export class KeyboardHelper {

  /**
   * helper function for listen key hitting
   *
   * @param key the key name which click should be Listen 
   *
   * @return `Observable<KeyboardEvent>`
   */
  public static listenKeyPress(key: string = 'enter') {
    return fromEvent<KeyboardEvent>(document, 'keyup')
      .pipe(
        filter((event) => event.key.toLowerCase() === key),
        map(event => {
          event.preventDefault();
          event.stopPropagation();
          return event;
        }),
        debounceTime(DEFAULT_DELAY)
      );
  }
}
