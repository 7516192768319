import { IImage } from '@models/common-models/image';
import { ILocationItem } from '@models/locations/location-item';
import { IVatRate } from '@models/utils/vat';
import {ISelectedAttributesList} from '@models/attributes/selected-attributes-list';
import { IAttributesGroup } from '..';

interface IArtist {
    artist_id: string;
    name_first: string;
    name_last: string;
}

export interface IStockSearchTerm {
    search: string;
    status: string;
    sortField?: string;
    sortDirection?: string;
}

export interface ISubcategory {
    archived: number;
    category_description: string;
    category_id: string;
    category_image: string;
    category_keywords: string;
    category_name_json: { [key: string]: string };
    category_status: string;
    parent_category: string;
    permalink: string;
    sort_order: number;
    sync_key: string;
    sync_last: string;
    vat_id: string;
}

export interface ICategory {
    category_id: string;
    category_name_json: { [key: string]: string };
    children: ISubcategory[];
    stock_category_id?: string;
}

export interface IShippingClass {
    description: string;
    shipping_class_id: string;
}


export interface IStockConstants {
    artists: IArtist[];
    categories: ICategory[];
    shipping_classes: IShippingClass[];
    vat_rates: IVatRate[];
    locations: ILocationItem[];
    next_stock_number: number;
    settings: {
        default_metric_unit_size: string;
        default_metric_unit_weight: string;
        default_troy_ounce_show: string;
        option_reserved_items: string;
        stock_default_available_to_buy: string;
        option_prices_sale: string;
        stock_default_price_visible: string;
    };
}

export interface IStockImage {
    url?: string;
    image_url: string;
    list_url: string;
    thumb_url: string;
    alt_text?: string;
    archived: number;
    image_id: string;
    position: number;
    image_name: string;
    image_type: string;
    visible: number;
    image_visible: number;
    main_image: number;
    parent_id: string;
    permalink: string;
    sync_key: string;
    sync_last: string;
}

export interface IStockCreateCategory {
    parent_id: string;
    children: string[];
}

export interface IDimension {
    dimensions_height: number;
    dimensions_width: number;
    dimensions_depth: number;
    dimensions_diameter: number;
    dimensions_weight: number;
    dimensions_troy_ounce_show: number;
    troyOz?: boolean;
    dimensions_size_unit?: string;
    dimensions_weight_unit?: string;
}

export class ICreateEditStockRequest {
    stock_id: string;
    stock_number_own_ref: string;
    quantity: number;
    title: { [key: string]: string };
    price_retail: number;
    price_trade: number;
    price_sale: number;
    price_sale_visible: number;
    description: { [key: string]: string };
    dimensions_height: number;
    dimensions_width: number;
    dimensions_depth: number;
    dimensions_diameter: number;
    dimensions_weight: number;
    dimensions_framed_height: number;
    dimensions_framed_width: number;
    dimensions_framed_depth: number;
    dimensions_framed_diameter: number;
    dimensions_framed_weight: number;
    dimensions_troy_ounce_show: number;
    dimensions_size_unit: string;
    dimensions_weight_unit: string;
    extra_information: IExtraInformation;
    categories: IStockCreateCategory[];
    all_categories: string[];
    artists: number[];
    shipping_class_id: number;
    keywords: { [key: string]: string };
    reserved: number;
    featured: number;
    private_view: number;
    multiple: number;
    discontinued: number;
    show_item: number;
    visible_sort_date: string;
    video_link: string;
    available_to_buy: number;
    price_visible: number;
    vat_type: string;
    vat_rate_id: string;
    video_type: string;
    archived: string;
    status: 'sold' | 'available' | 'reserved';
    images: any[];
    permalink: string;
    attribute_groups: IAttributesGroup[];
    constructor() {
        this.images = [];
        this.categories = [];
        this.all_categories = [];
        this.artists = [];
        this.extra_information = {} as any;
        this.keywords = {};
        this.featured = 0;
        this.shipping_class_id = null;
        this.video_type = null;
        this.available_to_buy = 1;
        this.video_link = '';
        this.video_link = '';
        this.private_view = 0;
        this.reserved = 0;
        this.attribute_groups = [];
    }
}

export interface IStocksResponse {
    stock_ids: string[];
    count_of_stock: number;
    data: {
        current_page: number;
        data: IStockListItem[];
        first_page_url: string;
        from: number;
        last_page: number;
        last_page_url: string;
        next_page_url: string;
        path: string;
        per_page: number;
        prev_page_url: string;
        to: number;
        total: number;
    };
}

export interface IStockListItem {
    id: string;
    image: string;
    price_retail: number;
    quantity: number;
    title_json?: { [key: string]: string };
    title: string;
    status: string;
    available_to_buy: number;
    visible_sort_date: string;
    stock_number: string;
    visible: boolean;
    show_item: boolean;
    stock_id?: string;
    images?: IStockImage[];
    stock_number_own_ref: string;
    permalink: string;
}

export interface IStockColumnItem {
    name: string;
    key: string;
    sortOrder: 'ascend' | 'descend' | '';
}

export interface IStockItem {
    categories: ICategory[];
    artists: IArtist[];
    archived: number;
    available_to_buy: number;
    description: string;
    dimensions_depth: number;
    dimensions_diameter: number;
    dimensions_framed_depth: number;
    dimensions_framed_diameter: number;
    dimensions_framed_height: number;
    dimensions_framed_weight: number;
    dimensions_framed_width: number;
    dimensions_height: number;
    dimensions_size_unit: string;
    dimensions_troy_ounce_show: number;
    dimensions_weight: number;
    dimensions_weight_unit: string;
    dimensions_width: number;
    extraInfo_awards: { [key: string]: string };
    extraInfo_collections: { [key: string]: string };
    extraInfo_condition: { [key: string]: string };
    extraInfo_country: { [key: string]: string };
    extraInfo_editionNumber: { [key: string]: string };
    extraInfo_exhibitions: { [key: string]: string };
    extraInfo_isbnNumber: string;
    extraInfo_literature: { [key: string]: string };
    extraInfo_medium: { [key: string]: string };
    extraInfo_period: { [key: string]: string };
    extraInfo_provenance: { [key: string]: string };
    extraInfo_signed: { [key: string]: string };
    extraInfo_style: { [key: string]: string };
    extraInfo_year: { [key: string]: string };
    extraInfo_notes: { [key: string]: string };
    featured_Item: number;
    images: IStockImage[];
    keywords: string;
    multiple: number;
    discontinued: number;
    permalink: string;
    price_retail: number;
    price_sale: number;
    price_sale_visible: number;
    price_trade: number;
    price_visible: number;
    quantity: number;
    reserved: number;
    shipping_class_id: string;
    status: string;
    stock_id: string;
    main_image?: IImage;
    image_url?: string;
    stock_number: string;
    stock_number_own_ref: string;
    stock_location_id: string;
    sync_key: string;
    sync_last: string;
    tags: string;
    title: string;
    vat_rate_id: string;
    vat_type: string;
    video_link: string;
    video_type: string;
    visible: number;
    show_item: number;
    show_price_on_web: number;
    visible_sort_date: string;
    navigation: IStockNavigation;
    private_view: number;
    keywords_json: { [key: string]: string };
    title_json: { [key: string]: string };
    description_json: { [key: string]: string };
    extraInfo_period_json: { [key: string]: string };
    extraInfo_year_json: { [key: string]: string };
    extraInfo_medium_json: { [key: string]: string };
    extraInfo_country_json: { [key: string]: string };
    extraInfo_signed_json: { [key: string]: string };
    extraInfo_style_json: { [key: string]: string };
    extraInfo_provenance_json: { [key: string]: string };
    extraInfo_awards_json: { [key: string]: string };
    extraInfo_literature_json: { [key: string]: string };
    extraInfo_exhibitions_json: { [key: string]: string };
    extraInfo_collections_json: { [key: string]: string };
    extraInfo_editionNumber_json: { [key: string]: string };
    extraInfo_condition_json: { [key: string]: string };
    extraInfo_notes_json: { [key: string]: string };
    stock_attribute_groups: IAttributesGroup[];
    attribute_groups: IAttributesGroup[];
}

export interface IStockNavigation {
    first: string;
    last: string;
    next: string;
    previous: string;
}

export interface IStockBasics {
    stock_number_own_ref: string;
    vat_type: string;
    vat_rate_id: string;
    stockNumber: string;
    quantity: number;
    all_categories: any;
    artists: string[];
    title: string;
    price_retail: number;
    price_trade: number;
    price_sale: number;
    price_sale_visible: boolean;
    available_to_buy: boolean;
    description: string;
    stock_location_id: string;
    show_item: boolean;
    status: string;
    visible_sort_date: string;
    price_visible: boolean;
    reserved: boolean;
    stockConstants?: IStockConstants;
    private_view?: boolean;
    featured?: boolean;
    multiple?: boolean;
    discontinued?: boolean;
    title_json: { [key: string]: string };
    description_json: { [key: string]: string };
}

export interface IWebSiteOptions {
    keywords: string;
    shipping_class_id: string;
    vimeoLink?: string;
    youTubeLink?: string;
    video_type: string;
    keywords_json: { [key: string]: string };
}

export interface IExtraInformation {
    extraInfo_period?: { [key: string]: string };
    extraInfo_year?: { [key: string]: string };
    extraInfo_medium?: { [key: string]: string };
    extraInfo_country?: { [key: string]: string };
    extraInfo_signed?: { [key: string]: string };
    extraInfo_style?: { [key: string]: string };
    extraInfo_provenance?: { [key: string]: string };
    extraInfo_awards?: { [key: string]: string };
    extraInfo_literature?: { [key: string]: string };
    extraInfo_exhibitions?: { [key: string]: string };
    extraInfo_collections?: { [key: string]: string };
    extraInfo_editionNumber?: { [key: string]: string };
    extraInfo_condition?: { [key: string]: string };
    extraInfo_notes?: { [key: string]: string };
    extraInfo_isbnNumber?: string;
    extraInfo_condition_json?: { [key: string]: string };
    extraInfo_period_json?: { [key: string]: string };
    extraInfo_year_json?: { [key: string]: string };
    extraInfo_medium_json?: { [key: string]: string };
    extraInfo_country_json?: { [key: string]: string };
    extraInfo_signed_json?: { [key: string]: string };
    extraInfo_style_json?: { [key: string]: string };
    extraInfo_provenance_json?: { [key: string]: string };
    extraInfo_awards_json?: { [key: string]: string };
    extraInfo_literature_json?: { [key: string]: string };
    extraInfo_exhibitions_json?: { [key: string]: string };
    extraInfo_collections_json?: { [key: string]: string };
    extraInfo_editionNumber_json?: { [key: string]: string };
    extraInfo_notes_json?: { [key: string]: string };
}

export interface IBasicSearch {
    stock_number_own_ref: string;
    category: string[];
    artists: string[];
    title: string;
    price_retail: number;
    price_trade: number;
    price_sale: number;
    quantity: number | string;
    image_quantity: number | string;
    description: string;
    shipping_class: string;
    visible: boolean;
    reserved: boolean;
    featured_item: boolean;
    available_to_buy: boolean;
    price_visible: boolean;
    stock_number: string;
    status: string;
}

export interface IDimensionsSearch {
    dimensions_width: number;
    dimensions_height: number;
    dimensions_depth: number;
    dimensions_diameter: number;
    dimensions_weight: number;
    dimensions_framed_width: number;
    dimensions_framed_height: number;
    dimensions_framed_depth: number;
    dimensions_framed_diameter: number;
    dimensions_framed_weight: number;
}

export interface IExtraSearch {
    extraInfo_period: string;
    extraInfo_literature: string;
    extraInfo_year: string;
    extraInfo_exhibitions: string;
    extraInfo_medium: string;
    extraInfo_collections: string;
    extraInfo_country: string;
    extraInfo_editionNumber: string;
    extraInfo_signed: string;
    extraInfo_style: string;
    extraInfo_isbnNumber: string;
    extraInfo_provenance: string;
    extraInfo_awards: string;
    extraInfo_condition: string;
    privateNotes: string;
}

export interface IAdvancedSearch {
    basic: IBasicSearch;
    dimensions: IDimensionsSearch;
    extra: IExtraSearch;
}

export interface IAdvancedSearchRequest {
    stock_number?: string;
    stock_number_own_ref?: string;
    title?: string;
    description?: string;
    price_retail?: string;
    price_trade?: string;
    price_sale?: string;
    quantity?: string;
    extraInfo_period?: string;
    extraInfo_year?: string;
    extraInfo_medium?: string;
    extraInfo_country?: string;
    extraInfo_signed?: string;
    extraInfo_style?: string;
    extraInfo_provenance?: string;
    extraInfo_awards?: string;
    extraInfo_literature?: string;
    extraInfo_exhibitions?: string;
    extraInfo_collections?: string;
    extraInfo_condition?: string;
    extraInfo_editionNumber?: string;
    extraInfo_isbnNumber?: string;
    dimensions_height?: string;
    dimensions_width?: string;
    dimensions_depth?: string;
    dimensions_diameter?: string;
    dimensions_weight?: string;
    dimensions_framed_height?: string;
    dimensions_framed_width?: string;
    dimensions_framed_depth?: string;
    dimensions_framed_weight?: string;
    visible?: string;
    reserved?: string;
    featured_item?: string;
    available_to_buy?: number;
    price_visible?: string;
    shipping_class?: string;
    status?: string;
    category?: number[];
    artists?: string[];
}

export const enum SearchTypes {
    ADVANCED = 'advanced',
    BASIC = 'basic'
}
