import { IWidgetList } from '@models/index';

export const WIDGET_LIST: IWidgetList[] = [
  {
    title: 'Artists List',
    image: 'assets/images/widgets/artist-list-2-new.png',
    type: 'artist-list'
  },
  {
    title: 'Stock List',
    image: 'assets/images/widgets/stock-list.png',
    type: 'stock-item'
  },
  {
    title: 'Stock Detail',
    image: 'assets/images/widgets/stock-detail-page.png',
    type: 'product-single'
  },
  {
    title: 'Menu',
    image: 'assets/images/widgets/menu.png',
    type: 'menu'
  },
];
