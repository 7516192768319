
import { ILanguage } from '@models/languages/language';

export class AttributesTranslation {
  attribute_group_name: { [key: string]: string };
  attribute_group_desc: { [key: string]: string };
  constructor(languages: ILanguage[]) {
    languages.forEach(language => {
      this.attribute_group_name = {
        ...this.attribute_group_name,
        [language.code]: ''
      };
      this.attribute_group_desc = {
        ...this.attribute_group_desc,
        [language.code]: ''
      };
    });
  }
}
