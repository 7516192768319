import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAssociationLogo } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class EcommerceService {

  constructor(private _httpClient: HttpClient) { }
  // TODO Needs typing
  public getEcommerce() {
    return this._httpClient.get<any>(`ecommerce`);
  }
  // TODO Needs typing
  public updateEcommerce(post: any) {
    return this._httpClient.post<void>(`ecommerce`, { data: post });
  }
}
