export interface IImageUploadResponse {
    url: string;
}

export interface IStockImageUploadResponse {
    url: {
        image_url: string;
        list_url: string;
        thumb_url: string;
    };
}

export interface IImageDeleteRequest {
    file: string;
    type: string;
}

export enum EImageTypes {
    BLOG = 'blog',
    EVENT = 'events',
    STOCK = 'stock',
    ARTIST = 'artist',
    ASSOCIATION_LOGO = 'association_logo',
    CATEGORY_BOX = 'category_box',
    COMPANY_LOGO = 'logo',
    CATEGORY = 'category',
    PRESS = 'press',
    ABOUT = 'about',
    CONTACT = 'contact',
    VIDEO = 'video',
    PAYMENT = 'payment',
    GENERAL = 'general'
}
