import { IFaqSave } from '.';
import { ILanguage } from '../languages/language';

export class FaqTranslation {
    title_json: { [key: string]: string };
    content_json: { [key: string]: IFaqSave };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.title_json = {
                ...this.title_json,
                [language.code]: ''
            };
            this.content_json = {
                ...this.content_json,
                [language.code]: {
                    main_content: '',
                    groups: []
                }
            };
        });
    }
}
