import { ILanguage } from '@models/languages/language';

export class GeneralTranslation {
    company_name: { [key: string]: string };
    copyright_message: { [key: string]: string };
    design_phone_number_in_header: { [key: string]: string };
    print_pdf_footer: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.company_name = {
                ...this.company_name,
                [language.code]: ''
            };
            this.copyright_message = {
                ...this.copyright_message,
                [language.code]: ''
            };
            this.design_phone_number_in_header = {
                ...this.design_phone_number_in_header,
                [language.code]: ''
            };
            this.print_pdf_footer = {
                ...this.print_pdf_footer,
                [language.code]: ''
            };
        });
    }
}
