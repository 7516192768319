<form nz-form nzLayout="vertical">
  <p>{{"MATTERPORT.TITLE" | translate}}</p>
  <div class="d-flex justify-content-between">
    <nz-form-item class="w-100">
      <nz-form-control>
        <nz-select [formControl]="matterport_url" nzPlaceHolder="Please Select" nzMode="tags">
          <nz-option *ngFor="let matterport of allMatterports" [nzValue]="matterport" [nzLabel]="matterport.title"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <button nz-button nzType="primary" class="ml-2 " nzGhost (click)="addNewUrl()"
      [disabled]="matterport_url.value ? matterport_url.invalid : true">+
      {{"MATTERPORT.SAVE_URL" | translate}}</button>
  </div>
</form>
<label nz-checkbox [formControl]="newTab">Open in new Tab</label>
<div nz-row>

  <div nz-col nzSpan="8" *ngFor="let url of urlList; let index = index" class="mt-3">
    <nz-card style="width:300px;">
      <div class="d-flex justify-content-between">
        <div>
          <div class="absolute-div" (click)="goToMatterport(url)" *ngIf="newTab.value"></div>
          <iframe width='230' height='150' [src]='"https://my.matterport.com/show/?m=" +  url | safe: "resourceUrl"'
          frameborder='0' allowfullscreen></iframe>
        </div>
        <div class="icons ml-3">
          <!-- <i class="edit-icon" (click)="showModal(url, index)" nz-icon nzType="edit" nzTheme="outline"></i> -->
          <i class="delete-icon" nz-popconfirm [nzPopconfirmTitle]="'MATTERPORT.DELETE_CONFIRM_MESSAGE' | translate"
            (nzOnConfirm)="removeUrl(index)"  nz-icon nzType="delete"
            nzTheme="outline"></i>
        </div>
      </div>
    </nz-card>
  </div>

</div>

