import { ILanguage } from '../languages/language';

export class CatalogueTranslation {
    catalog_desc_json: { [key: string]: string };
    catalog_subtitle_json: { [key: string]: string };
    catalog_title_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
    description_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.catalog_title_json = {
                ...this.catalog_title_json,
                [language.code]: ''
            };
            this.catalog_subtitle_json = {
                ...this.catalog_subtitle_json,
                [language.code]: ''
            };
            this.catalog_desc_json = {
                ...this.catalog_desc_json,
                [language.code]: ''
            };
            this.keywords_json = {
              ...this.keywords_json,
              [language.code]: ''
            };
            this.description_json = {
              ...this.description_json,
              [language.code]: ''
            };
        });
    }
}
