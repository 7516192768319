import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IShippingClassItem, IShippingClassMessages, IShippingClassResponse, IUpdatePoaRequest } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ShippingClassesService {

    constructor(private _httpClient: HttpClient) { }

    public list() {
        return this._httpClient.get<IShippingClassResponse>('shipping-classes');
    }

    public add(data: IShippingClassItem) {
        return this._httpClient.post<{ shipping_class_id: number }>('shipping-classes', data);
    }

    public updatePoa(data: IUpdatePoaRequest) {
        return this._httpClient.post<ApiResponse<IShippingClassItem>>('shipping-classes-poa', data);
    }

    public update(data: { data: IShippingClassItem[] }) {
        return this._httpClient.put<void>(`shipping-classes-update`, data);
    }

    public updateShippingClassMessages(body: IShippingClassMessages) {
        return this._httpClient.post<void>(`shipping-classes-update-messages`, body);
    }

    public delete(id: string) {
        return this._httpClient.delete(`shipping-classes/${id}`);
    }
}
