import { Observable } from 'rxjs';
import { Directive, Input, AfterViewInit, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { LoadingService } from '@services/index';

@Directive({
  selector: '[appInitializeAfterInit]',
  exportAs: 'initializeAfterInit'
})
export class InitializeAfterInitDirective implements OnInit, AfterViewInit {


  @Input('appInitializeAfterInit') second: number;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('appInitializeAfterInitLoading') loadingTemplate: TemplateRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    ) { }

  ngOnInit(): void {
    if (this.loadingTemplate) {
      this.container.createEmbeddedView(this.loadingTemplate);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.container.clear();
      this.container.createEmbeddedView(this.templateRef);
    }, this.second);
  }

}
