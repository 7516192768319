import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
type AOA = any[][];

@Injectable({
  providedIn: 'root'
})
export class BulkUploadService {

  constructor(private _httpClient: HttpClient) { }

  public contactsBulkUpload(file: {data: unknown[]}) {
      return this._httpClient.post('bulk-upload/contacts', file);
  }

  public stockBulkUpload(file: {data: unknown[]}) {
    return this._httpClient.post('bulk-upload/stock', file);
  }

  public artistsBulkUpload(file: {data: unknown[]}) {
    return this._httpClient.post('bulk-upload/artists', file);
  }

  public downloadContactsTemplate() {
    return this._httpClient.get('bulk-upload/export/contacts', { responseType: 'blob'});
  }

  public downloadArtistsTemplate() {
    return this._httpClient.get('bulk-upload/export/artists', { responseType: 'blob'});
  }

  public downloadStockTemplate() {
    return this._httpClient.get('bulk-upload/export/stock', { responseType: 'blob'});
  }

  public artistValidateFromServer(file: {data: unknown[]}) {
    return this._httpClient.post('bulk-upload/validate/artists', file);
  }

  public stockValidateFromServer(file: {data: unknown[]}) {
    return this._httpClient.post('bulk-upload/validate/stock', file);
  }

  public contactValidateFromServer(file: {data: unknown[]}) {
    return this._httpClient.post('bulk-upload/validate/contacts', file);
  }
}
