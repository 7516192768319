import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IBlog, IDeleteBlogRequest, IChangeBlogVisibilityRequest, IBlogsResponse, IBlogCreate } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class BlogsService {

    constructor(private _httpClient: HttpClient) { }

    public getBlogs(page: number) {
        return this._httpClient.get<ApiResponse<IBlogsResponse>>(`blog?page=${page}`);
    }

    public createBlog(blog: IBlogCreate) {
        return this._httpClient.post<ApiResponse<IBlogCreate[]>>('blog', blog);
    }

    public getBlogById(id: string) {
        return this._httpClient.get<ApiResponse<IBlog>>(`blog/${id}/edit`);
    }

    public editBlog(blog: IBlogCreate) {
        return this._httpClient.put<any>(`blog/${blog.blog_id}`, blog);
    }

    public deleteBlog(data: IDeleteBlogRequest) {
        return this._httpClient.delete<ApiResponse<void>>(`blog/${data.id}`);
    }

    public changeVisibility(data: IChangeBlogVisibilityRequest) {
        return this._httpClient.post<ApiResponse<void>>('blog-visible', data);
    }
}
