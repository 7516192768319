import { ILanguage } from '@models/languages/language';

export class ArtistTypeTranslation {
    type_list_json: { [key: string]: string };
    type_detail_json: { [key: string]: string };
    type_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.type_list_json = {
                ...this.type_list_json,
                [language.code]: ''
            };
            this.type_detail_json = {
                ...this.type_detail_json,
                [language.code]: ''
            };
            this.type_json = {
                ...this.type_json,
                [language.code]: ''
            };
        });
    }
}
