import { FormGroup } from '@angular/forms';

export class ComponentDeactivateHelper {
    public static isFormTouched(form: FormGroup): boolean {
        let formTouched = true;
        for (const control of Object.keys(form.controls)) {
            const value = form.controls[control];
            if (value.touched) {
                formTouched = false;
                break;
            }
        }
        return formTouched;
    }
}
