import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Components } from '@components/export';
import { Directives } from '@directives/export';
import { TranslateModule } from '@ngx-translate/core';
import { Pipes } from '@pipes/export';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';


@NgModule({
  declarations: [
    ...Pipes,
    ...Components,
    ...Directives,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    FormsModule,
    NzButtonModule,
    NzDividerModule,
    NzIconModule,
    NzToolTipModule,
    NzInputModule,
    NzInputNumberModule,
    NzTabsModule,
    ColorPickerModule,
    NzPageHeaderModule,
    NzTagModule,
    GooglePlaceModule,
    NzFormModule,
    NzCardModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    NzModalModule,
    NzSelectModule
  ],
  exports: [
    ...Components,
    ...Pipes,
    ...Directives,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    GooglePlaceModule,
  ]
})
export class SharedModule { }
