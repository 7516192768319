import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IShippingOptions,  ApiResponse } from '@models/index';


@Injectable({
    providedIn: 'root'
})
export class ShippingOptionsService {

  constructor(private _httpClient: HttpClient) { }

  getOptions() {
    return this._httpClient.get<ApiResponse<IShippingOptions>>('shopping-option-get');
  }

  postOptions(data: IShippingOptions) {
    return this._httpClient.post('shopping-option-add', data);
  }
}
