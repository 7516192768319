export class VideoHelper {
  static isVideo(url: string) {
    const lastDotIndex = url.lastIndexOf('.');
    let type = '';
    for (let index = lastDotIndex +1; index < url.length; index++) {
      type += url[index];
    }
    for (const iterator of ['mp4', 'mov', 'wmv', 'avi', 'webm']) {
      if (type === iterator) {
         return true;
      }
    }
    return false;
  }
}
