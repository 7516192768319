import { EListViewType } from '@enums/index';
import { IListViewWidgetPreview } from '@models/index';
import { MENU_VIEW_ELEMENT } from './menu-view-element';

export const AVAILABLE_STOCK_DETAIL_WIDGETS: IListViewWidgetPreview[] = [
    {
        name: 'Style 1',
        type: EListViewType.STYLE_ONE,
        image: 'assets/images/widgets/stock-detail-2.png',
        data: [],
        description: 'Silver template design with a large primary image and thumbnails underneath.'
    },
    {
        name: 'Style 2',
        type: EListViewType.STYLE_SIX,
        image: 'assets/images/widgets/stock-detail-3.png',
        data: [],
        description: 'Studio template design. All images are large (full width to the browser left edge) and listed vertically.'

    },
    {
        name: 'Style 3',
        type: EListViewType.STYLE_THREE,
        image: 'assets/images/widgets/stock-detail-page.png',
        data: [],
        description: 'Infinity template design. All images are large and listed vertically.'
    },
    {
        name: 'Style 4',
        type: EListViewType.STYLE_SEVEN,
        image: 'assets/images/widgets/stock-detail-4.png',
        data: [],
        description: 'Pioneer template design. Large primary image with sliders to view additional images.'
    },
    {
      name: 'Style 5',
      type: EListViewType.STYLE_FIVE,
      image: 'assets/images/widgets/stock-detail-5.png',
      data: [],
      description: 'Transitions / Sapphire template design with a large primary image and thumbnails underneath.'
    },

];
