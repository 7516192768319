import { PAYPAL_IMAGES, PAYPAL_NAMES } from './paypal';
import { STRIPE_IMAGES, STRIPE_NAMES } from './stripe';
import { TAKE_PAYMENTS_IMAGES, TAKE_PAYMENTS_NAMES } from './take-payments';


export const PAYMENT_NAMES: { [key: string]: string[] } = {
    stripe: STRIPE_NAMES,
    paypal: PAYPAL_NAMES,
    takepayments: TAKE_PAYMENTS_NAMES,
};

export const PAYMENT_IMAGES: { [key: string]: { label: string; image: string }[] } = {
    stripe: STRIPE_IMAGES,
    paypal: PAYPAL_IMAGES,
    takepayments: TAKE_PAYMENTS_IMAGES,
};
