export class IContact {
  title: string;
  googleKey: string;
  description: {
    description: string;
    show: number;
  };
  images: {
    url: string[];
    show: number;
  };
  coordinates: IContactCoordinate[];

  constructor() {
    this.googleKey = '';
    this.description = {
      description: '',
      show: 1
    };
    this.images = {
      url: [],
      show: 1
    };
    this.coordinates = [];
  }
}
export interface IContactCoordinate {
  name: string;
  lat: number;
  lng: number;
}
