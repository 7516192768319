import { IListViewElement } from './list-view-element';

export class IListViewWidget {
    contact_order: IListViewElement[];
    text_settings: {
        text_style: string;
        text_position: string;
    };
    option_hover_image: '1' | '0';
    maxColumnCount?: string;
    constructor(
        contact_order: IListViewElement[]
    ) {
        this.contact_order = contact_order;
        this.text_settings = {
            text_position: 'text-left',
            text_style: 'text-normal'
        };
        this.option_hover_image = '0';
        this.maxColumnCount = '';
    }
}
