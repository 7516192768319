import { ILanguage } from '@models/languages/language';

export class OrderConfirmationTranslation {
    subject: { [key: string]: string };
    header_text: { [key: string]: string };
    order_details_phrase: { [key: string]: string };
    order_number_phrase: { [key: string]: string };
    ref_no_phrase: { [key: string]: string };
    image_phrase: { [key: string]: string };
    price_each_phrase: { [key: string]: string };
    quantity_phrase: { [key: string]: string };
    subtotal_phrase: { [key: string]: string };
    date_and_time_phrase: { [key: string]: string };
    contact_information_phrase: { [key: string]: string };
    shipping_phrase: { [key: string]: string };
    name_phrase: { [key: string]: string };
    tax_vat_phrase: { [key: string]: string };
    company_name_phrase: { [key: string]: string };
    total_phrase: { [key: string]: string };
    phone_phrase: { [key: string]: string };
    payment_method_phrase: { [key: string]: string };
    dont_ship_phrase: { [key: string]: string };
    voucher_applied_phrase: { [key: string]: string };
    email_phrase: { [key: string]: string };
    payment_reference_phrase: { [key: string]: string };
    tax_vat_invoice_phrase: { [key: string]: string };
    shipping_address_phrase: { [key: string]: string };
    billing_address_phrase: { [key: string]: string };
    email_has_been_sent_phrase: { [key: string]: string };
    shipping_note_phrase: { [key: string]: string };
    discount_phrase: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.subject = {
                ...this.subject,
                [language.code]: ''
            };
            this.header_text = {
                ...this.header_text,
                [language.code]: ''
            };
            this.order_details_phrase = {
                ...this.order_details_phrase,
                [language.code]: ''
            };
            this.order_number_phrase = {
                ...this.order_number_phrase,
                [language.code]: ''
            };
            this.ref_no_phrase = {
                ...this.ref_no_phrase,
                [language.code]: ''
            };
            this.image_phrase = {
                ...this.image_phrase,
                [language.code]: ''
            };
            this.price_each_phrase = {
                ...this.price_each_phrase,
                [language.code]: ''
            };
            this.quantity_phrase = {
                ...this.quantity_phrase,
                [language.code]: ''
            };
            this.subtotal_phrase = {
                ...this.subtotal_phrase,
                [language.code]: ''
            };
            this.date_and_time_phrase = {
                ...this.date_and_time_phrase,
                [language.code]: ''
            };
            this.contact_information_phrase = {
                ...this.contact_information_phrase,
                [language.code]: ''
            };
            this.shipping_phrase = {
                ...this.shipping_phrase,
                [language.code]: ''
            };
            this.name_phrase = {
                ...this.name_phrase,
                [language.code]: ''
            };
            this.tax_vat_phrase = {
                ...this.tax_vat_phrase,
                [language.code]: ''
            };
            this.company_name_phrase = {
                ...this.company_name_phrase,
                [language.code]: ''
            };
            this.total_phrase = {
                ...this.total_phrase,
                [language.code]: ''
            };
            this.phone_phrase = {
                ...this.phone_phrase,
                [language.code]: ''
            };
            this.payment_method_phrase = {
                ...this.payment_method_phrase,
                [language.code]: ''
            };
            this.dont_ship_phrase = {
                ...this.dont_ship_phrase,
                [language.code]: ''
            };
            this.voucher_applied_phrase = {
                ...this.voucher_applied_phrase,
                [language.code]: ''
            };
            this.email_phrase = {
                ...this.email_phrase,
                [language.code]: ''
            };
            this.payment_reference_phrase = {
                ...this.payment_reference_phrase,
                [language.code]: ''
            };
            this.tax_vat_invoice_phrase = {
                ...this.tax_vat_invoice_phrase,
                [language.code]: ''
            };
            this.shipping_address_phrase = {
                ...this.shipping_address_phrase,
                [language.code]: ''
            };
            this.shipping_address_phrase = {
                ...this.shipping_address_phrase,
                [language.code]: ''
            };
            this.billing_address_phrase = {
                ...this.billing_address_phrase,
                [language.code]: ''
            };
            this.email_has_been_sent_phrase = {
                ...this.email_has_been_sent_phrase,
                [language.code]: ''
            };
            this.shipping_note_phrase = {
                ...this.shipping_note_phrase,
                [language.code]: ''
            };
            this.discount_phrase = {
                ...this.discount_phrase,
                [language.code]: ''
            };
        });
    }
}
