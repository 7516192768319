import { ILanguage } from '@models/languages/language';

export class PaymentsTranslation {
  payment_types_text: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.payment_types_text = {
                ...this.payment_types_text,
                [language.code]: ''
            };
        });
    }
}
