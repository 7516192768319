import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  private _path: string = 'assets/images';

  constructor() { }

  // imageName: string = `${this.assetsPath}/image-example.png`;

  // get the path from outside
  public get assetsPath() {
    return this._path;
  }
}
