import { Injectable } from '@angular/core';
import {
  APP_DEFAULT_DATE_FORMAT,
  APP_DEFAULT_TIME_FORMAT,
  DEFAULT_DELAY
} from '@constants/index';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  windowWidth: number = 0;
  windowHeight: number = 0;
  bodyWidth: number = 0;
  bodyHeight: number = 0;
  // behavior subject of device width
  windowWidthChange: BehaviorSubject<number> = new BehaviorSubject(0);
  windowHeightChange: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor() {

    this.getWindowAndBodySizes();

    // after listen the changes
    fromEvent(window, 'resize')
      .pipe(startWith(''), debounceTime(DEFAULT_DELAY))
      .subscribe(_ => {
        this.getWindowAndBodySizes();
      });
  }

  /**
   * for get the current window and body sizes
   *
   * @param null
   *
   * @return `void`
   */
  getWindowAndBodySizes() {
    this.windowWidthChange.next(window.innerWidth);
    this.windowHeightChange.next(window.innerHeight);
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.bodyWidth = document.body.clientWidth;
    this.bodyHeight = document.body.clientHeight;
  }

  /**
   * get the app date format
   *
   * @param null
   *
   * @return `string`
   */
  get dateFormat() {
    return APP_DEFAULT_DATE_FORMAT;
  }

  /**
   * get the app time format
   *
   * @param null
   *
   * @return `string`
   */
  get timeFormat() {
    return APP_DEFAULT_TIME_FORMAT;
  }

}
