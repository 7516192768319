import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, INewsletters } from '@models/index';


@Injectable({
    providedIn: 'root'
})
export class NewsletterService {

    constructor(private _httpClient: HttpClient) { }

    public getNewsletters() {
        return this._httpClient.get<ApiResponse<INewsletters>>('newsletters');
    }

    public updateNewsletters(body: INewsletters) {
        return this._httpClient.post<void>(`newsletters`, body);
    }

}
