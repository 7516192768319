export const CM_TO_INCHES_DELTA = 0.39370079;
export const MM_TO_INCHES_DELTA = 0.039370079;
export const KG_TO_LB_DELTA = 0.453;
export const G_TO_LB_DELTA = 0.000453;
export const KG_TO_TROY_DELTA = 32.1507;
export const STOCK_LOCATION_HISTORY_DATE_FORMAT = 'yyyy-MM-dd';
export const STOCK_VIDEO_MAX_SIZE = 5000000;
export const STOCK_VIDEO_MIN_SIZE = 1;

export const enum VideoLinks {
    YOUTUBE = 'Youtube',
    VIMEO = 'Vimeo',
}
