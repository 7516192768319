import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'app-hideable-input',
  templateUrl: './hideable-input.component.html',
  styleUrls: ['./hideable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: HideableInputComponent,
      multi: true,
    },
  ],
})
export class HideableInputComponent implements OnInit {
  @ViewChild('input', { static: true }) private _input: ElementRef;
  @Output() touched: EventEmitter<void> = new EventEmitter<void>();
  @Input() width: number;
  @Input() overflow: string;
  @Input() type: string = 'number';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  public value: FormControl = new FormControl('', [Validators.required]);
  public showInput: boolean = false;

  constructor(private _changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void {
    this._subscribeToValueChange();
  }

  private _subscribeToValueChange(): void {
    this.value.valueChanges.subscribe((v) => {
      this._propagateChange(v);
    });
  }

  private _propagateChange = (_: any) => { };

  public writeValue(value: string | number): void {
    this.value.setValue(value);
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }

  public toggleShowInput(): void {
    this.showInput = !this.showInput;
    if (this.showInput) {
      this._changeDetection.detectChanges();
      this._input?.nativeElement.focus();
    }

  }
}
