import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ICountries, IStates, ITaxSettings, IUpdateTaxSettings } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class TaxSettingsService {

  private _countriesStateBaseURL: string = 'https://countriesnow.space/api/v0.1/countries/states';

  constructor(private _httpClient: HttpClient) { }

  public createTaxAccount() {
    return this._httpClient.post<void>('create-custom-account', {});
  }

  public getDefaultTaxRate(country_code: string) {
    return this._httpClient.get<{ rate: number }>(`get-tax-rate/${country_code}`);
  }

  public updateTaxSettings(body: IUpdateTaxSettings) {
    return this._httpClient.post<void>(`tax-settings`, body);
  }

  public getTaxSettings() {
    return this._httpClient.get<ApiResponse<ITaxSettings>>(`tax-settings`);
  }

  public deleteJurisdiction(ids: number[]) {
    return this._httpClient.post(`jurisdiction_delete`, { deleted_ids: ids });
  }

  public getCountries() {
    return this._httpClient.get<ApiResponse<ICountries[]>>(`tax-countries`);
  }

  public getStates(value: string) {
    console.log(value);

    const headers = new HttpHeaders('skip');
    return this._httpClient.post<ApiResponse<IStates>>(
      this._countriesStateBaseURL,
      { country: value }, { headers });
  }
}
