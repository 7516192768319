import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ICustomPages, ICustomPagesResponse, IToggleCustomPagesVisibility } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class CustomPagesService {

  constructor(private _httpClient: HttpClient) { }

  public getPolicies() {
    return this._httpClient.get<ICustomPagesResponse[]>(`custom-pages`);
  }

  public get(type: string) {
    return this._httpClient.get<ApiResponse<ICustomPagesResponse>>(`custom-pages/${type}/edit`);
  }

  public add(data: ICustomPages) {
    return this._httpClient.post<ApiResponse<ICustomPagesResponse>>(`custom-pages`, data);
  }

  public update(data: ICustomPages, type: string) {
    return this._httpClient.put<ApiResponse<ICustomPagesResponse>>(`custom-pages/${type}`, data);
  }

  public delete(type: string) {
    return this._httpClient.delete(`custom-pages/${type}`);
  }

  public toggleVisibility(data: IToggleCustomPagesVisibility) {
    return this._httpClient.put<void>('custom-pages-visible', data);
  }
}
