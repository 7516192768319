import { ILanguage } from '@models/languages/language';

export class AttributeTagsTranslation {
  name: { [key: string]: string };
  constructor(languages: ILanguage[]) {
    languages.forEach(language => {
      this.name = {
        ...this.name,
        [language.code]: ''
      };
    });
  }
}
