import { ILanguage } from '../languages/language';

export class GoogleKeysTranslations {
    google_key_analytics: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.google_key_analytics = {
                ...this.google_key_analytics,
                [language.code]: ''
            };
        });
    }
}
