import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IGeneral } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private _httpClient: HttpClient) { }

  public getGeneral() {
    return this._httpClient.get<IGeneral>(`get-general`);
  }

  public updateGeneral(post: IGeneral) {
    return this._httpClient.put<void>(`update-general`, { data: post });
  }

}
