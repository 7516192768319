import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, ITradeRequestResponse } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class TradeRequestService {

  constructor(private _httpClient: HttpClient) { }

  public get(page: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<ApiResponse<ITradeRequestResponse>>(`trade-requests`, { params });
  }

  public acceptReject(id: number, accept: boolean) {
    const body = {id, accept};
    return this._httpClient.put(`trade-requests/decide/${id}`, body);
  }

}
