import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IIntegrationStockResponse } from '@models/index';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IntegrationsStockService {

  constructor(private _httpClient: HttpClient) { }

  public get(id: string): Observable<IIntegrationStockResponse> {
    return this._httpClient.get<IIntegrationStockResponse>(`integration-get-stock-data/${id}`);
  }
}
