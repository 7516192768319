import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BaseApiService } from '@api';
import { ILanguage, ISettings } from '@models/index';

@Component({
  selector: 'app-languages-tab',
  templateUrl: './languages-tab.component.html',
  styleUrls: ['./languages-tab.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguagesTabComponent implements OnInit {
  @Output() languageChange: EventEmitter<ILanguage> = new EventEmitter();
  @Input() languages: ILanguage[] = [];
  @Input() hideStatusIcons: boolean = false;
  @Input() selectedTabIndex: number = 0;
  @Output() selectedTabIndexChange: EventEmitter<number> = new EventEmitter<number>();

  public settings: ISettings;

  constructor(
    private _baseAPI: BaseApiService
  ) { }

  ngOnInit(): void {
    this.settings = this._baseAPI.settings.settings;
    this.onLanguageChange(0);
  }

  public onLanguageChange(index: number): void {
    this.selectedTabIndex = index;
    this.selectedTabIndexChange.emit(this.selectedTabIndex);
    this.languageChange.emit(this.languages[index]);
  }

}
