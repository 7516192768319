
import { ILanguage } from '../languages/language';

export class StockLocationTranslation {
    notes_json: { [key: string]: string };


    constructor(languages: ILanguage[]) {
        languages.forEach((language) => {
            this.notes_json = {
                ...this.notes_json,
                [language.code]: ''
            };
        });
    }
}
