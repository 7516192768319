<!-- <div class="page-heading d-flex align-items-center justify-content-between" [class.sticky]="sticky">
  <div class="d-flex align-items-center">
    <p class="title m-0">{{title}}</p>
    <ng-content select="[additionalTitle]"></ng-content>
  </div>
    <ng-content></ng-content>
    <button *ngIf="!hideButton" (click)="onAddClicked()" nz-button nzType="default" [nzSize]="'large'" nzShape="circle"
        class="add-button"><i nz-icon nzType="plus"></i></button>
</div>
<nz-divider></nz-divider> -->

<nz-page-header class="page-header" (nzBack)="onBackClick()" [nzBackIcon]="showBackButton ? 'arrow-left' : null" [nzTitle]="title">
  <nz-page-header-tags>
    <nz-tag [nzColor]="'blue'">{{companyName | titlecase}}</nz-tag>
  </nz-page-header-tags>
  <nz-page-header-extra>
    <ng-content select="[actions]"></ng-content>
  </nz-page-header-extra>
  <nz-page-header-footer>
    <ng-content select="[footer]"></ng-content>
  </nz-page-header-footer>
</nz-page-header>
