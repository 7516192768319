import { ILanguage } from '@models/languages/language';

export class TradeRequestDelaerTranslation {
  subject: { [key: string]: string };
  header_text: { [key: string]: string };
  date_and_time_phrase: { [key: string]: string};
  name_phrase: { [key: string]: string};
  mobile_phrase: { [key: string]: string};
  phone_phrase: { [key: string]: string};
  company_phrase: { [key: string]: string};
  email_address_phrase: { [key: string]: string};
  accept_phrase: { [key: string]: string};
  reject_phrase: { [key: string]: string};
  email_has_been_sent_phrase: { [key: string]: string};

  constructor(languages: ILanguage[]) {
    languages.forEach(language => {
        this.subject = {
            ...this.subject,
            [language.code]: ''
        };
        this.header_text = {
            ...this.header_text,
            [language.code]: ''
        };
        this.date_and_time_phrase = {
            ...this.date_and_time_phrase,
            [language.code]: ''
        };
        this.name_phrase = {
            ...this.name_phrase,
            [language.code]: ''
        };
        this.company_phrase = {
            ...this.company_phrase,
            [language.code]: ''
        };
        this.email_address_phrase = {
            ...this.email_address_phrase,
            [language.code]: ''
        };
        this.phone_phrase = {
            ...this.phone_phrase,
            [language.code]: ''
        };
        this.mobile_phrase = {
            ...this.mobile_phrase,
            [language.code]: ''
        };
        this.accept_phrase = {
            ...this.accept_phrase,
            [language.code]: ''
        };
        this.reject_phrase = {
            ...this.reject_phrase,
            [language.code]: ''
        };

        this.email_has_been_sent_phrase = {
            ...this.email_has_been_sent_phrase,
            [language.code]: ''
        };

      });
    }
}
