import { NotifierOptions } from 'angular-notifier';

export const APP_NOTIFIER_OPTIONS: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 10
    },
    vertical: {
      position: 'top',
      distance: 10,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: false,
    showDismissButton: true,
    stacking: 6
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 400

  }
};

