import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    ApiResponse,
    IEventCreate,
    IEventsResponse,
    IEvent,
    IDeleteEventRequest,
    IChangeEventVisibilityRequest
} from '@models/index';
import { EEventTicketNameCheckResponse } from '@enums/event-name-check-response.enum';

@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor(private _httpClient: HttpClient) { }

    public getEvents(page: number) {
        return this._httpClient.get<ApiResponse<IEventsResponse>>(`events?page=${page}`);
    }

    public getEventById(id: string) {
        return this._httpClient.get<ApiResponse<IEvent>>(`events/${id}/edit`);
    }

    public createEvent(event: IEventCreate) {
        return this._httpClient.post<ApiResponse<IEventCreate[]>>('events', event);
    }

    public editEvent(event: IEventCreate) {
        return this._httpClient.put<any>(`events/${event.event_id}`, event);
    }

    public deleteEvent(data: IDeleteEventRequest) {
        return this._httpClient.delete<ApiResponse<void>>(`events/${data.id}`);
    }

    public changeVisibility(data: IChangeEventVisibilityRequest) {
        return this._httpClient.post<ApiResponse<void>>('events-visible', data);
    }

    public checkIfTicketFileNameAlreadyExists(name: string) {
        const params = new HttpParams().set('name', name);
        return this._httpClient.get<{ status: EEventTicketNameCheckResponse }>('check-ticket-image-name', { params });
    }
}
