import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IEmailTemplateCommon,
    IUpdateEmailTemplate,
    IEmailTemplateListItem,
    EmailTemplate,
    EmailTemplateUpdate
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class EmailTemplatesService {

    constructor(private _httpClient: HttpClient) { }

    public getTemplatesList() {
        return this._httpClient.get<{ [key: string]: IEmailTemplateListItem[] }>('email-templates');
    }

    public getEmailTemplate(templateId: string) {
        return this._httpClient.get<IEmailTemplateCommon<EmailTemplate>>(`email-templates/${templateId}`);
    }

    public updateEmailTemplate(templateId: string, body: IUpdateEmailTemplate<EmailTemplateUpdate>) {
        return this._httpClient.put(`email-templates/${templateId}`, body);
    }

}
