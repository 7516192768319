import { ILanguage } from '../languages/language';

export class CategoryBoxesTranslation {
    main_title_json: { [key: string]: string };
    title_json: { [key: string]: string }[];

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.main_title_json = {
                ...this.main_title_json,
                [language.code]: ''
            };
            this.title_json = [];
        });
    }
}
