import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    ApiResponse,
    IAppendMessageRequest,
    ICountries,
    ILocationItem,
    ILocationMessageRequest,
    IState,
    IStates,
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class LocationsService {

    private _countriesStateBaseURL: string = 'https://countriesnow.space/api/v0.1/countries/states';

    constructor(private _httpClient: HttpClient) { }

    public getLocationList() {
        return this._httpClient.get<{ data: ILocationItem[], web_setting_value: { [key: string]: string } }>('location');
    }

    public getCountries() {
        return this._httpClient.get<ApiResponse<ICountries[]>>(`location-countries`);
    }

    public getStates(value: string) {
        const headers = new HttpHeaders('skip');
        return this._httpClient.post<ApiResponse<IStates>>(
            this._countriesStateBaseURL,
            { country: value }, { headers });
    }

    public addLocation(data: ILocationItem) {
        return this._httpClient.post<void>('location', data);
    }

    public editLocation(data: ILocationItem) {
        return this._httpClient.put<void>(`location/${data.location_id}`, data);
    }

    public deleteLocation(id: string) {
        return this._httpClient.delete(`location/${id}`);
    }

    public appendMessage(data: IAppendMessageRequest) {
        return this._httpClient.post('location-advanced-message', data);
    }

    public saveMessage(data: ILocationMessageRequest) {
        return this._httpClient.post('location-advanced-message-add', data);
    }
}
