export class VimeoHelper {
  static getVideoId(url: string): string {
    if (url) {
      let regex = (/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/);
      return regex.exec(url)[5];
    } else {
      return '';
    }
  }
}
