import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, IPagesMenu } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private _httpClient: HttpClient) { }

  public getPages() {
    return this._httpClient.get<IPagesMenu>(`get-page-and-menu`);
  }

  public updatePages(post: IPagesMenu) {
    return this._httpClient.put<void>(`update-page-and-menu`, { data: post });
  }

  public resetPagesMenu() {
    return this._httpClient.get<ApiResponse<IPagesMenu>>('get-default-pages');
  }
}
