import { ILanguage } from '../languages/language';
import { IContact } from './contact';

export class ContactTranslation {
    title_json: { [key: string]: string };
    content_json: { [key: string]: any };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.title_json = {
                ...this.title_json,
                [language.code]: ''
            };
            this.content_json = {
                ...this.content_json,
                [language.code]: new IContact()
            };
        });
    }
}
