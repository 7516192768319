import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse, IExhibition, IPaginationResponse
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ExhibitionsService {

    constructor(private _httpClient: HttpClient) { }


    public list(page: number) {
        const params = new HttpParams().set('page', page.toString());
        return this._httpClient.get<ApiResponse<IPaginationResponse<IExhibition[]>>>('exhibits', { params });
    }

    public get(id: string) {
        return this._httpClient.get<ApiResponse<IExhibition>>(`exhibits/${id}`);
    }

    public add(data: FormData) {
        return this._httpClient.post('exhibits', data);
    }

    public update(body: FormData, id: string) {
        return this._httpClient.post(`exhibits/${id}`, body);
    }

    public delete(id: string) {
        return this._httpClient.delete(`exhibits/${id}`);
    }

    public changeVisibility(body: FormData) {
        return this._httpClient.post(`exhibits/${body.get('id')}/visibility`, body);
    }
}
