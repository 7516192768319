import { ILanguage } from '../languages/language';

export class BlogTranslation {
    blog_title_json: { [key: string]: string };
    blog_json: { [key: string]: string };
    blog_keywords_json: { [key: string]: string};
    blog_description_json: { [key: string]: string};
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.blog_title_json = {
                ...this.blog_title_json,
                [language.code]: ''
            };
            this.blog_json = {
                ...this.blog_json,
                [language.code]: ''
            };
            this.blog_keywords_json = {
                ...this.blog_keywords_json,
                [language.code]: ''
            };
            this.blog_description_json = {
                ...this.blog_description_json,
                [language.code]: ''
            };
        });
    }
}
