import { IImage } from '..';

export class IArtist {
    archived: number;
    artist_id: string;
    all_count: number;
    on_web_and_sold: number;
    artist_image_default: string;
    artist_image_source: string;
    artist_image_stock_id: string;
    artists_image: {
        image_url: string;
    };
    last_stock_image: string;
    image: IImage;
    biography_json: { [key: string]: string };
    description_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
    biography: { [key: string]: string } | string;
    description: { [key: string]: string } | string;
    keywords: { [key: string]: string } | string;
    featured: number;
    name_first: string;
    name_last: string;
    permalink: string;
    stock_count: number;
    sync_key: string;
    sync_last: string;
    template_id: string;
    template_json: string;
    type: number;
    visible: number;
    year_born: number;
    year_died: number;
    videos: {
        file: File,
        url: string;
        title: string;
        visible: string;
        filename: string;
    }[];
}

export interface IArtistVideo {
    source: File | string;
    preview: string;
    filename: string;
    visible: boolean;
    title: string;
    videoType: 'youtube' | 'custom' | 'gif';
}
