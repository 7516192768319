import {Directive, ElementRef, HostBinding, Input, OnInit} from '@angular/core';
import {timer} from 'rxjs';

@Directive({
  selector: '[appRowHighlighter]',
})
export class RowHighlighterDirective implements OnInit{
  @HostBinding('style.transition') color = 'background-color 500ms linear';
  @Input('appRowHighlighter') isLast: boolean;
  constructor(private elRef: ElementRef) {}

  ngOnInit(): void {
    if (this.isLast){
      const start = timer(300);
      start.subscribe(_ => {
        this.elRef.nativeElement.style.background = "#dcdcdc";
      });
      const end = timer(2000);
      end.subscribe(_ => {
        this.elRef.nativeElement.style.background = "";
      });
    }
  }

}
