import { IMatterPort } from "@models/utils/matterport";

export enum EBlogStatuses {
    EDITING = 'editing',
    PUBLISHED = 'published'
}

export interface IDeleteBlogRequest {
    id: string;
}

export interface IChangeBlogVisibilityRequest {
    id: string;
    visible: boolean;
}

export interface IBlogsResponse {
    current_page: number;
    data: IBlog[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

export interface IBlog {
    archived: 0;
    blog_html: string;
    blog_id: string;
    blog_image_name: string;
    blog_published_date: string;
    blog_slug: string;
    blog_status: string;
    blog_title_json: { [key: string]: string };
    blog_json: { [key: string]: string };
    blog_title: string;
    sync_key: string;
    sync_last: string;
    visible?: boolean;
    matterport_links:IMatterPort[];
    keywords_json: { [key: string]: string};
    description_json: { [key: string]: string};
    blog_keywords: string;
    blog_description: string;
}

export interface IBlogCreate {
    blog_id?: string;
    blog_title: { [key: string]: string };
    img_url: string;
    publish_date: string;
    blog_content: { [key: string]: string };
    visible?: boolean;
    matterport_ids: string[];
    keywords: { [key: string]: string};
    description: { [key: string]: string};
}
