import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Injectable()
export class Cleaner implements OnDestroy {


    // private array of subscribers
    // store the subscribers into the subscribers property
    // for a destroy when destroys the component
    private _subscribers$: Subscription = new Subscription();

    public unsubscriber$: Subject<void> = new Subject<void>();




    /**
     * store the subscription for unsubscribing on component `OnDestroy` 
     *
     * @param subscriber$ with type `Subscription`
     *
     * @return `void`
     */
    set subscriber$(subscriber: Subscription) {
        this._subscribers$.add(subscriber);
    }

    // unsubscribe from  all subscriptions
    ngOnDestroy(): void {
        this._subscribers$.unsubscribe();

        this.unsubscriber$.next();
        this.unsubscriber$.complete();
    }

}
