import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaginationResponse, IPostPress, IPress, ITogglePressVisibility } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class PressService {

  constructor(private _httpClient: HttpClient) { }

  public list(page: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<IPaginationResponse<IPress[]>>(`press-media`,{ params });
  }

  public get(id: string) {
    return this._httpClient.get<IPress>(`press-media/${id}`);
  }

  public create(post: IPostPress) {
    return this._httpClient.post(`press-media`, post);
  }

  public update(post: IPostPress, id: string) {
    return this._httpClient.put(`press-media/${id}`, post);
  }

  public delete(id: string) {
    return this._httpClient.delete(`press-media/${id}`);
  }

  public toggleVisible(data: ITogglePressVisibility) {
    return this._httpClient.put('press-media-visible', data);
  }
}
