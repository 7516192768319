import { ILanguage } from '@models/languages/language';

export class ExhibitionTranslation {
    exhibit_title_json: { [key: string]: string };
    exhibit_subtitle_json: { [key: string]: string };
    location_text_json: { [key: string]: string };
    exhibit_description_json: { [key: string]: string };
    exhibit_tag_json: { [key: string]: any };
    description_json: { [key: string]: any };
    keywords_json: { [key: string]: any };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.exhibit_title_json = {
                ...this.exhibit_title_json,
                [language.code]: ''
            };
            this.exhibit_subtitle_json = {
                ...this.exhibit_subtitle_json,
                [language.code]: ''
            };
            this.location_text_json = {
                ...this.location_text_json,
                [language.code]: ''
            };
            this.exhibit_description_json = {
                ...this.exhibit_description_json,
                [language.code]: ''
            };
            this.exhibit_tag_json = {
                ...this.exhibit_tag_json,
                [language.code]: ''
            };
            this.keywords_json = {
              ...this.keywords_json,
              [language.code]: ''
            };
            this.description_json = {
              ...this.description_json,
              [language.code]: ''
            };
        });
    }
}
