<form nz-form nzLayout="vertical">
  <p>{{"MATTERPORT.TITLE" | translate}}</p>
    <nz-form-item class="w-100">
      <nz-form-control [nzErrorTip]="validationMessage">
        <input nz-input placeholder="Add URL" [formControl]="matterport">
      </nz-form-control>
    </nz-form-item>
</form>

<ng-template #validationMessage let-control>
  <ng-container *ngIf="control.hasError('invalidMatterportURL')">
      {{'MATTERPORT.VALIDATION_MESSAGE' | translate}}
  </ng-container>
</ng-template>
