import { Injectable } from '@angular/core';
import { getBase64 } from '@helpers/image-preview';
import { NgxImageCompressService } from 'ngx-image-compress';

@Injectable({
    providedIn: 'root'
})
export class CompressService {

    constructor(
        private _imageCompress: NgxImageCompressService
    ) { }

    public compressFile(image: File, fileName: string, ratio: number = 70, quality: number = 70): Promise<File> {
        const orientation = -1;

        return new Promise((res) => {
            getBase64(image, (img) => {
                res(new Promise((resolve, reject) => {
                    this._imageCompress.compressFile(img, orientation, ratio, quality).then(
                        (result) => {
                            const imageBlob = this._dataURItoBlob(result.split(',')[1]);
                            resolve(new File([imageBlob], fileName, { type: 'image/jpeg' }));
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                }));
            });
        });
    }

    private _dataURItoBlob(dataURI: string): Blob {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([int8Array], { type: 'image/jpeg' });
    }
}
