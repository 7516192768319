import { CANADA_DEFAULT_TAX } from '@constants/index';

export class ITaxJurisdiction {

    constructor(
        public country = '',
        public country_code = '',
        public created_at = '',
        public id = null,
        public is_primary = 0,
        public state = '',
        public state_code = '',
        public tax_rate = '',
        public tax_value = '',
        public updated_at = '',
        public zip = '',
    ) {
        if (this.state === 'none') {
            this.state = CANADA_DEFAULT_TAX.name;
        }

        if (Number(this.tax_rate) <= 0) {
            this.tax_rate = '';
        }
    }
}
