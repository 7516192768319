import { ILanguage } from '../languages/language';

export class MenuOrderTranslation {
    name_json: { [key: string]: string };
    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.name_json = {
                ...this.name_json,
                [language.code]: ''
            };
        });
    }
}
