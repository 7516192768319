import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IWebsiteStaticTexts } from '@models/index';
import { IUpdateWebsiteStaticTexts } from '@models/website-static-texts/update-website-static-texts';

@Injectable({
    providedIn: 'root'
})
export class WebsiteStaticTextsService {

    constructor(private _httpClient: HttpClient) { }

    public getStaticTextsByLanguageCode(languageCode: string) {
        return this._httpClient.get<IWebsiteStaticTexts>(`phrases/${languageCode}`);
    }

    public updateStaticTexts(languageCode: string, body: IUpdateWebsiteStaticTexts) {
        return this._httpClient.put(`phrases/${languageCode}`, body);
    }

    public resetStaticTexts(languageCode: string) {
        return this._httpClient.get<IWebsiteStaticTexts>(`phrases/${languageCode}/reset`);
    }
}
