import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ApiResponse,
  IAnalyticsPeriod,
  IContactsAnalytics,
  IContactsAnalyticsByCountry, ISalesCategory,
  ISalesOrdersAnalytics,
  IStockAnalytics,
  IStockCategoryAnalytics
} from '@models/index';
import {ISalesAnalyticsByCountry} from '@models/charts/sales-by-country';
import {ITaxAnalytics} from '@models/charts/tax';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(private _httpClient: HttpClient) {
  }

  public getStockAnalytics() {
    return this._httpClient.get<ApiResponse<IStockAnalytics>>('analytics/stock');
  }

  public getStockCategoryAnalytics() {
    return this._httpClient.get<ApiResponse<IStockCategoryAnalytics[]>>('analytics/categories');
  }

  public getContactsAnalytics(period?: IAnalyticsPeriod) {
    const params = new HttpParams({
      fromObject: period ? {
        period_start: period.period_start,
        period_end: period.period_end,
      } : {}
    });
    return this._httpClient.get<ApiResponse<IContactsAnalytics>>('analytics/contacts', {params});
  }

  public getContactsAnalyticsByCountry() {
    return this._httpClient.get<ApiResponse<IContactsAnalyticsByCountry>>('analytics/contacts-by-country');
  }

  public getEnquiresAnalytics(period?: IAnalyticsPeriod) {
    const params = new HttpParams({
      fromObject: period ? {
        period_start: period.period_start,
        period_end: period.period_end,
      } : {}
    });
    return this._httpClient.get<ApiResponse<IContactsAnalytics>>('analytics/enquires', {params});
  }

  public getSalesAnalyticsByCountry() {
    return this._httpClient.get<ApiResponse<ISalesAnalyticsByCountry>>('analytics/sales-by-country');
  }

  public getSalesOrdersByCategoryAnalytics() {
    return this._httpClient.get<ApiResponse<ISalesCategory[]>>('analytics/sales-by-stock-category');
  }

  public getSalesOrderAnalytics() {
    return this._httpClient.get<ApiResponse<ISalesOrdersAnalytics>>('analytics/orders');
  }

  public getTaxesByCountryAnalytics() {
    return this._httpClient.get<ApiResponse<ITaxAnalytics[]>>('analytics/taxes-by-country');
  }
}
