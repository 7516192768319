import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse,
    IArtistType,
    IPaginationResponse
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ArtistTypesService {

    constructor(private _httpClient: HttpClient) { }

    public getList(page: number) {
        const params = new HttpParams().append('page', String(page));
        return this._httpClient.get<ApiResponse<IPaginationResponse<IArtistType[]>>>('artist-types', { params });
    }

    public addArtistType(data: IArtistType) {
        return this._httpClient.post<void>('artist-types', data);
    }

    public editArtistType(data: IArtistType) {
        return this._httpClient.put<void>(`artist-types/${data.id}`, data);
    }

    public deleteArtistType(id: number) {
        return this._httpClient.delete(`artist-types/${id}`);
    }
}
