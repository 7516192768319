
export interface IQuestionsGroup {
    title: string;
    items: IQuestionsItem[];
}
export class IQuestionsItem {
    public question: string;
    public answer: string;
    public visible: boolean;
    constructor() {
        this.question = '';
        this.answer = '';
        this.visible = true;
    }
}
