import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IPaymentMethod, IPaymentType, ITakePayment } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _httpClient: HttpClient) { }

  public get() {
    return this._httpClient.get<ApiResponse<IPaymentType[]>>(`payments`);
  }

  public configurate(body: FormData, type: string) {
    return this._httpClient.post(`payments/${type}`, body);
  }

  public changeStatus(type: string, status: boolean) {
    return this._httpClient.put(`payments/${type}/active`, { active: Number(status) });
  }

  public sendTakePayments(data: ITakePayment) {
    return this._httpClient.post(`add-partner`, data);
  }

  public getPaymentMethod() {
    return this._httpClient.get<ApiResponse<IPaymentMethod>>('payments/payment-type-text');
  }

  public sendPaymentMethod(data) {
    return this._httpClient.post('payments/payment-type-text', data);
  }

}
