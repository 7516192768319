
import { ILanguage } from '../languages/language';

export class StockTranslation {
    title_json: { [key: string]: string };
    keywords_json: { [key: string]: string };
    description_json: { [key: string]: string };
    extraInfo_period_json: { [key: string]: string };
    extraInfo_year_json: { [key: string]: string };
    extraInfo_medium_json: { [key: string]: string };
    extraInfo_country_json: { [key: string]: string };
    extraInfo_signed_json: { [key: string]: string };
    extraInfo_style_json: { [key: string]: string };
    extraInfo_provenance_json: { [key: string]: string };
    extraInfo_awards_json: { [key: string]: string };
    extraInfo_literature_json: { [key: string]: string };
    extraInfo_exhibitions_json: { [key: string]: string };
    extraInfo_collections_json: { [key: string]: string };
    extraInfo_editionNumber_json: { [key: string]: string };
    extraInfo_notes_json: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach((language) => {
            this.title_json = {
                ...this.title_json,
                [language.code]: ''
            };
            this.keywords_json = {
                ...this.keywords_json,
                [language.code]: ''
            };
            this.description_json = {
                ...this.description_json,
                [language.code]: ''
            };
            this.extraInfo_period_json = {
                ...this.extraInfo_period_json,
                [language.code]: ''
            };
            this.extraInfo_year_json = {
                ...this.extraInfo_year_json,
                [language.code]: ''
            };
            this.extraInfo_medium_json = {
                ...this.extraInfo_medium_json,
                [language.code]: ''
            };
            this.extraInfo_country_json = {
                ...this.extraInfo_country_json,
                [language.code]: ''
            };
            this.extraInfo_signed_json = {
                ...this.extraInfo_signed_json,
                [language.code]: ''
            };
            this.extraInfo_style_json = {
                ...this.extraInfo_style_json,
                [language.code]: ''
            };
            this.extraInfo_provenance_json = {
                ...this.extraInfo_provenance_json,
                [language.code]: ''
            };
            this.extraInfo_awards_json = {
                ...this.title_json,
                [language.code]: ''
            };
            this.extraInfo_literature_json = {
                ...this.extraInfo_literature_json,
                [language.code]: ''
            };
            this.extraInfo_exhibitions_json = {
                ...this.extraInfo_exhibitions_json,
                [language.code]: ''
            };
            this.extraInfo_collections_json = {
                ...this.extraInfo_collections_json,
                [language.code]: ''
            };
            this.extraInfo_editionNumber_json = {
                ...this.extraInfo_editionNumber_json,
                [language.code]: ''
            };
            this.extraInfo_notes_json = {
                ...this.extraInfo_notes_json,
                [language.code]: ''
            };
        });
    }
}
