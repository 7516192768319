import { ILanguage } from '@models/languages/language';

export class TradeRequestTranslation {
  subject: { [key: string]: string };
  email_has_been_sent_phrase: { [key: string]: string };
  header_text: { [key: string]: string };

  constructor(languages: ILanguage[]) {
    languages.forEach(language => {
        this.subject = {
            ...this.subject,
            [language.code]: ''
        };
        this.email_has_been_sent_phrase = {
            ...this.email_has_been_sent_phrase,
            [language.code]: ''
        };
        this.header_text = {
            ...this.header_text,
            [language.code]: ''
        };
      });
    }
}
