import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IResponseLanguages, IStandardFeatures } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class StandardFeaturesService {

    constructor(private _httpClient: HttpClient) { }

    public update(value: IStandardFeatures) {
        return this._httpClient.put<void>(`update-website-features`, { data: value });
    }

    public get() {
        return this._httpClient.get<IStandardFeatures>('get-website-features');
    }

    public reset() {
        return this._httpClient.get<ApiResponse<IStandardFeatures>>('get-default-website-features');
    }

    public getLanguagesFromGoogle() {
        const params = new HttpParams()
            .append('key', 'AIzaSyCmMhHfBIcYKNNETgRtzQeD1_yitk1li0Y')
            .append('target', 'en');
        return this._httpClient.get<IResponseLanguages>('https://translation.googleapis.com/language/translate/v2/languages', { params });
    }
}
