import { ILanguage } from '@models/languages/language';

export class NewslettersTranslations {
    title_json: { [key: string]: string }[][];

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.title_json = {
                ...this.title_json,
                [language.code]: []
            };
        });
    }
}
