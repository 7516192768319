import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BaseApiService } from '@api';
import { RouterLinks } from '@constants/index';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class LoginPageGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _baseAPI: BaseApiService,
    ) { }

    canActivate(): Observable<boolean> | boolean {
        return this._baseAPI.settings.getSettings()
            .pipe(
                map((data) => {
                    if (!data.business_manager_old) {
                        return true;
                    }
                    if (Number(data.business_manager_old)) {
                        this._router.navigate([RouterLinks.WEB_PAGES]);
                    } else {
                        this._router.navigate([RouterLinks.STOCKS_LIST]);
                    }
                    return false;
                }),
                catchError(() => {
                    return of(true);
                })
            );
    }
}
