import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse, ISortingOptions } from '@models/index';


@Injectable({
    providedIn: 'root'
})
export class SortingOptionsService {

  constructor(private _httpClient: HttpClient) { }

  get() {
    return this._httpClient.get<ApiResponse<ISortingOptions[]>>(`sorting-options`);
  }

  post(data: ISortingOptions[]) {
    return this._httpClient.post<ISortingOptions[]>(`sorting-options-settings`, {sorting_options: data});
  }

}
