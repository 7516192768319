import { EListViewType } from '@enums/index';
import { IListViewWidget, IListViewWidgetPreview } from '@models/index';
import { LIST_VIEW_ELEMENTS } from './list-view-elements';

export const AVAILABLE_STOCK_LIST_WIDGETS: IListViewWidgetPreview[] = [
    {
        name: 'Style 1',
        type: EListViewType.STYLE_ONE,
        image: 'assets/images/widgets/type-one.png',
        data: new IListViewWidget(LIST_VIEW_ELEMENTS),
        description: 'Infinity template design with beautiful large images in a grid format. Item details are displayed when you hover over the image.'
    },
    {
        name: 'Style 2',
        type: EListViewType.STYLE_TWO,
        image: 'assets/images/widgets/type-two.png',
        data: new IListViewWidget(LIST_VIEW_ELEMENTS),
        description: 'Silver template design based on a traditional grid with item details displayed below the image.'

    },
    // {
    //     name: 'Style 3',
    //     type: EListViewType.STYLE_THREE,
    //     image: 'assets/images/widgets/type-three.png',
    //     data: new IListViewWidget(LIST_VIEW_ELEMENTS),
    //     description: 'An alternative grid style with gracefully bordered images'
    // },
    {
        name: 'Style 3', // Style title decreased because Style 3 is commented
        type: EListViewType.STYLE_FOUR,
        image: 'assets/images/widgets/type-four.png',
        data: new IListViewWidget(LIST_VIEW_ELEMENTS),
        description: 'Transitions template design with a gentle animation where the images slide into place. Instead of using a fixed grid, images are adjusted to fit based on their dimensions. Item details are displayed below the image.'
    },
    // {
    //     name: 'Style 4', // Style title decreased because Style 3 is commented
    //     type: EListViewType.STYLE_FIVE,
    //     image: 'assets/images/widgets/type-four.png',
    //     data: new IListViewWidget(LIST_VIEW_ELEMENTS),
        // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        // tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
        // nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    // }
    {
      name: 'Style 4',
      type: EListViewType.STYLE_FIVE,
      image: 'assets/images/widgets/type-five.png',
      data: new IListViewWidget(LIST_VIEW_ELEMENTS),
      description: 'This is the default list view style from our Studio template, demonstrating a gentle animation with the images sliding into place, using a fixed grid.'
    },
    {
      name: 'Style 5',
      type: EListViewType.STYLE_SIX,
      image: 'assets/images/widgets/type-seven.png',
      data: new IListViewWidget(LIST_VIEW_ELEMENTS),
      description: 'This design uses a grid view that spans almost the full width of the page, with minimal borders.'
    }
];
