import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '@services/index';
import * as moment from 'moment';

@Pipe({
  name: 'cDate'
})
export class CDatePipe implements PipeTransform {

  constructor(private _configSrv: ConfigService) { }

  /**
   * transform the date to the format from config service
   * 
   * @param value with type `any`
   * @param format with type `string`
   * 
   * @return `string`
   */
  transform(
    value: any,
    format: string = this._configSrv.dateFormat.toUpperCase()
  ): any {
    if (!format || !value) { return value; }
    if (!(value instanceof Date)) { value = new Date(value); }
    return moment(value).format(format);
  }

}
