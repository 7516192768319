import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse,
    IChangeCategoriesOrder,
    IMoveCategoriesRequest,
    ISettingsCategoryItem,
    ISettingsCategoryVisible,
    IVatRate
} from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {

    constructor(private _httpClient: HttpClient) { }

    public getCategoriesList() {
        return this._httpClient.get<ApiResponse<ISettingsCategoryItem[]>>('category');
    }

    public addCategory(data: ISettingsCategoryItem) {
        return this._httpClient.post<void>('category', data);
    }

    public getCategoryById(id: string) {
        return this._httpClient.get<ApiResponse<ISettingsCategoryItem>>(`category/${id}/edit`);
    }

    public getVats() {
        return this._httpClient.get<IVatRate[]>('category-vats');
    }

    public editCategory(data: ISettingsCategoryItem) {
        return this._httpClient.put<void>(`category/${data.category_id}`, data);
    }

    public deleteCategory(id: string) {
        return this._httpClient.delete(`category/${id}`);
    }

    public editCategoryVisible(data: ISettingsCategoryVisible) {
        return this._httpClient.post(`category-visible`, data);
    }

    public moveStockToOtherCategories(data: IMoveCategoriesRequest) {
        return this._httpClient.post<void>('category-move', data);
    }

    public changeCategoriesOrder(body: IChangeCategoriesOrder) {
        return this._httpClient.post<void>('category-sort-order', body);
    }
}
