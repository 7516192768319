import { EListViewType } from '@enums/index';
import { IListViewWidgetPreview } from '@models/index';
import { MENU_VIEW_ELEMENT } from './menu-view-element';

export const AVAILABLE_ARTIST_LIST_WIDGETS: IListViewWidgetPreview[] = [
    {
        name: 'Style 1',
        type: EListViewType.STYLE_ONE,
        image: 'assets/images/widgets/artist-list-1-new.png',
        data: MENU_VIEW_ELEMENT,
        description: 'Image based. Artists sorted by type (a-z), e.g. Artists, Ceramicists, Sculptors, then sorted by surname & first name.'
    },
    {
        name: 'Style 2',
        type: EListViewType.STYLE_TWO,
        image: 'assets/images/widgets/artist-list-2-new.png',
        data: MENU_VIEW_ELEMENT,
        description: 'Image based. Artists sorted (a-z) by surname & first name.'

    },
    {
        name: 'Style 3',
        type: EListViewType.STYLE_SEVEN,
        image: 'assets/images/widgets/artist-list-3-new.png',
        data: MENU_VIEW_ELEMENT,
        description: 'A couple of rows of black & white images of randomly selected artists above with a text list of all artists below.'
    },
    {
        name: 'Style 4',
        type: EListViewType.STYLE_SIX,
        image: 'assets/images/widgets/artist-list-4-new.png',
        data: MENU_VIEW_ELEMENT,
        description: 'Text based. Sorted into headings A, B, C etc. with appropriate artists listed underneath.'
    },
    {
      name: 'Style 5',
      type: EListViewType.STYLE_NINE,
      image: 'assets/images/widgets/artist-list-5-new.png',
      data: MENU_VIEW_ELEMENT,
      description: 'Image based. A dynamically adjusted grid where images slide up to fill the gaps.'
    },
    {
      name: 'Style 6',
      type: EListViewType.STYLE_FOUR,
      image: 'assets/images/widgets/artist-list-6-new.png',
      data: MENU_VIEW_ELEMENT,
      description: 'Text based. A simple list of artists names, listed alphabetically.'
    },
    {
      name: 'Style 7',
      type: EListViewType.STYLE_EIGHT,
      image: 'assets/images/widgets/artist-list-7-new.png',
      data: MENU_VIEW_ELEMENT,
      description: 'Image based. As you hover over an artists name, the background image dynamically changes to the primary image for that artist.'
    },

];
