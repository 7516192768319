import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IArtist, IArtistSearchTerm, IArtistStock, IArtistVisibilityChange, IPaginationResponse } from '@models/index';

@Injectable({
    providedIn: 'root'
})
export class ArtistsService {

    constructor(private _httpClient: HttpClient) { }

    public list(searchTerm: IArtistSearchTerm, page: number) {
        const params = new HttpParams().append('page', String(page));
        return this._httpClient.post<ApiResponse<IPaginationResponse<IArtist[]>>>('artist-search', searchTerm, { params });
    }

    public getAll(page: number) {
        const params = new HttpParams().append('page', String(page));
        return this._httpClient.get<ApiResponse<IPaginationResponse<IArtist[]>>>('artist', { params });
    }

    public getArtistsStock(id: string) {
        return this._httpClient.get<ApiResponse<IArtistStock[]>>(`artist-stock-images/${id}`);
    }

    public get(id: string) {
        return this._httpClient.get<ApiResponse<IArtist>>(`artist/${id}/edit`);
    }

    public add(data: FormData) {
        return this._httpClient.post<void>('artist', data);
    }

    public edit(data: FormData) {
        return this._httpClient.post<void>(`artist/${data.get('artist_id')}`, data);
    }

    public changeVisibility(data: IArtistVisibilityChange) {
        return this._httpClient.post<void>('artist-visible', data);
    }

    public delete(id: string) {
        return this._httpClient.delete(`artist/${id}`);
    }
}
