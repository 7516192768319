import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IAttributes, ICreateAttributesGroup, IAttributesGroup, IAttributeTags, ICreateTag, ICreateAttribute} from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class AttributesService {

  constructor(private _httpClient: HttpClient) { }

  // Attributes Group

  public getAttributesGroup() {
    return this._httpClient.get<IAttributesGroup[]>('attribute-groups');
  }

  public getCurrentAttributesGroup(id: string) {
    return this._httpClient.get<IAttributesGroup>(`attribute-groups/${id}`);
  }

  public createAttributeGroup(attributeGroup: FormData) {
    return this._httpClient.post('attribute-groups', attributeGroup);
  }

  public updateCurrentAttributesGroup(id: string, attributesGroup: FormData) {
    return this._httpClient.post<IAttributesGroup>(`attribute-groups/${id}`, attributesGroup);
  }

  public attributesGroupOrdering(attributesIdList: {data: string[]}) {
    return this._httpClient.put('attribute-groups/ordering', attributesIdList);
  }

  public deleteAttributesGroup(id: string) {
    return this._httpClient.delete(`attribute-groups/${id}`);
  }

  // Attributes

  public getAttributes(groupId?: string) {
    const params = new HttpParams().append('attribute_group_id', String(groupId ?? ''));
    return this._httpClient.get<IAttributes[]>('attributes',{ params });
  }

  public getCurrentAttribute(id: string) {
    return this._httpClient.get<IAttributes>(`attributes/${id}`);
  }

  public updateCurrentAttribute(attribute: FormData, id: string) {
    return this._httpClient.post<IAttributes>(`attributes/${id}`, attribute);
  }

  public createAttribute(attribute: FormData) {
    return this._httpClient.post('attributes', attribute);
  }

  public attributesOrdering(attributesIdList: {data: string[]}) {
    return this._httpClient.put('attributes/ordering', attributesIdList);
  }

  public deleteAttribute(id: string) {
    return this._httpClient.delete(`attributes/${id}`);
  }

  // Tags

  public getAttributeTags() {
    return this._httpClient.get<IAttributeTags[]>('tags');
  }

  public getCurrentTag(id: string) {
    return this._httpClient.get<IAttributeTags>(`tags/${id}`);
  }

  public tagsOrdering(attributeTagsIdList: {data: string[]}) {
    return this._httpClient.put('tags/ordering', attributeTagsIdList);
  }

  public updateCurrentTag(tag: FormData, id: string) {
    return this._httpClient.post<IAttributes>(`tags/${id}`, tag);
  }

  public deleteTag(id: string) {
    return this._httpClient.delete(`tags/${id}`);
  }

  public createTag(tag: FormData) {
    return this._httpClient.post('tags', tag);
  }
}
