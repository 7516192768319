import { ISideNavItem, ISubNavigationItem } from '@models/index';

const imagesPath = 'assets/images/side-nav/';

export const DEFAULT_NAVIGATION_ITEMS: ISideNavItem[] = [
  // {
  //   label: 'NAVIGATION.DASHBOARD',
  //   routerLink: '/dashboard/charts',
  //   icon: imagesPath + 'chart.svg',
  //   access: ['0', '1']
  // },
  {
    label: 'NAVIGATION.CONTACTS',
    routerLink: '/dashboard/contacts',
    icon: imagesPath + 'contacts-logo-3.svg',
    access: ['0', '1']
  },
  {
    label: 'NAVIGATION.SALES',
    routerLink: '/dashboard/sales',
    icon: imagesPath + 'sale-2.svg',
    access: ['1', '0']
  },
  {
    label: 'NAVIGATION.STOCK',
    routerLink: '/dashboard/stock',
    icon: imagesPath + 'stock.svg',
    access: ['0']
  },
  {
    label: 'NAVIGATION.WEB_CONTENT',
    routerLink: '/dashboard/web-pages/banners',
    icon: imagesPath + 'web-content.svg',
    access: ['1', '0']
  },
  {
    label: 'NAVIGATION.WEB_DESIGN',
    routerLink: '/dashboard/web-pages/pages',
    icon: imagesPath + 'web-design.svg',
    access: ['1', '0']
  },
  {
    label: 'NAVIGATION.SETTINGS',
    routerLink: '/dashboard/settings',
    icon: imagesPath + 'settings.svg',
    access: ['1', '0']
  },
  {
    label: 'NAVIGATION.ECOMMERCE',
    routerLink: '/dashboard/ecommerce',
    icon: imagesPath + 'ecommerce-logo.svg',
    access: ['1', '0']
  },
  {
    label: 'NAVIGATION.INTEGRATION',
    routerLink: './dashboard/integration',
    icon: imagesPath + 'sales2.svg',
    access: ['0']
  },
];

export const CONTACTS_DEFAULT_ITEM: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.CONTACTS',
    routerLink: '/dashboard/contacts/contacts-list',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.TRADE_REQUEST',
    routerLink: '/dashboard/contacts/trade-request',
    access: ['0', '1']
  }
];


export const SALES_DEFAULT_ITEM: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.ENQUIRY',
    routerLink: '/dashboard/sales/enquiry',
    image: imagesPath + 'enquiry.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.ORDERS',
    routerLink: '/dashboard/sales/orders',
    image: imagesPath + 'orders.svg',
    access: ['1', '0']
  }
];

export const STOCK_DEFAULT_ITEMS: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.STOCK_LIST',
    routerLink: '/dashboard/stock/stock-list',
    image: imagesPath + 'stock-list.svg',
    access: ['0']
  },
];

export const CHARTS_DEFAULT_ITEMS: ISubNavigationItem[] = [];


export const WEB_CONTENT_DEFAULT_ITEMS: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.BANNERS',
    routerLink: '/dashboard/web-pages/banners',
    image: imagesPath + 'banners.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.BLOGS',
    routerLink: '/dashboard/web-pages/blogs',
    image: imagesPath + 'blogs.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.EVENTS',
    routerLink: '/dashboard/web-pages/events',
    image: imagesPath + 'events.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.PRESS',
    routerLink: '/dashboard/web-pages/press',
    image: imagesPath + 'press.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.CATALOGUE',
    routerLink: '/dashboard/web-pages/catalogue',
    image: imagesPath + 'general.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.EXHIBITIONS',
    routerLink: '/dashboard/web-pages/exhibitions',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.ABOUT',
    routerLink: '/dashboard/web-pages/about-us',
    image: imagesPath + 'about.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.CONTACT',
    routerLink: '/dashboard/web-pages/contact',
    // image: imagesPath + 'terms-conditions.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.FAQ',
    routerLink: '/dashboard/web-pages/faq',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.POLICY_PAGES',
    routerLink: '/dashboard/web-pages/policy-pages',
    access: ['1', '0']
  },
];


export const WEB_DESIGN_DEFAULT_ITEMS: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.PAGES',
    routerLink: '/dashboard/web-pages/pages',
    image: imagesPath + 'pages.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.COLOURS',
    routerLink: '/dashboard/web-pages/colours',
    image: imagesPath + 'colours.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.CATEGORY_BOXES',
    routerLink: '/dashboard/web-pages/category-boxes',
    image: imagesPath + 'category-boxes.svg',
    access: ['0', '1'],
    hide: true
  },
  {
    label: 'SUBNAVIGATION.ASSOCIATION_LOGO',
    routerLink: '/dashboard/web-pages/association-logo',
    image: imagesPath + 'association-logo.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.COMPANY_LOGO',
    routerLink: '/dashboard/web-pages/logos',
    image: imagesPath + 'company-logo.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.MENU_ORDER',
    routerLink: '/dashboard/web-pages/menu-order',
    image: imagesPath + 'association-logo.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.WIDGETS',
    routerLink: '/dashboard/web-pages/widgets',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.SORTING_OPTIONS',
    routerLink: '/dashboard/web-pages/sorting-options',
    access: ['0', '1']
  },
];

export const SETTINGS_DEFAULT_ITEMS: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.GENERAL',
    routerLink: '/dashboard/settings/general',
    image: imagesPath + 'general.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.STOCK_DEFAULTS',
    routerLink: '/dashboard/settings/stock-defaults',
    image: imagesPath + 'stock-general.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.STANDARD_FEATURES',
    routerLink: '/dashboard/settings/standard-features',
    image: imagesPath + 'standard-features.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.CATEGORIES_LIST',
    routerLink: '/dashboard/settings/categories/list',
    image: imagesPath + 'categories.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.ATTRIBUTES',
    routerLink: '/dashboard/settings/attributes',
    image: imagesPath + 'attributes.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.ARTIST_TYPES',
    routerLink: '/dashboard/settings/artist-types',
    image: imagesPath + 'artist-type.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.ARTISTS',
    routerLink: '/dashboard/settings/artists',
    image: imagesPath + 'artists.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.LOCATIONS',
    routerLink: '/dashboard/settings/locations',
    image: imagesPath + 'locations.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.SOCIAL_MEDIA',
    routerLink: '/dashboard/settings/social-media',
    image: imagesPath + 'social-media.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.GOOGLE',
    routerLink: '/dashboard/settings/google',
    image: imagesPath + 'api_keys.svg',
    access: ['0', '1']
  },
  // {
  //   label: 'SUBNAVIGATION.API_KEYS',
  //   routerLink: '/dashboard/settings/api-keys',
  //   image: imagesPath + 'api_keys.svg',
  //   access: ['0', '1']
  // },
  {
    label: 'SUBNAVIGATION.NEWSLETTER',
    routerLink: '/dashboard/settings/newsletter',
    image: imagesPath + 'api_keys.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.META',
    routerLink: '/dashboard/settings/meta-data',
    image: imagesPath + 'meta.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.ADMIN_SETTINGS',
    routerLink: '/dashboard/settings/admin-settings',
    image: imagesPath + 'settings.svg',
    access: ['1', '0']
  },
  {
    label: 'SUBNAVIGATION.WEBSITE_LANGUAGES',
    routerLink: '/dashboard/settings/website-languages',
    image: imagesPath + 'website-languages.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.WEBSITE_STATIC_TEXTS',
    routerLink: '/dashboard/settings/website-static-texts',
    image: imagesPath + 'website-languages.svg',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.EMAIL_TEMPLATES',
    routerLink: '/dashboard/settings/email-templates',
    access: ['0', '1']
  },
  // {
  //   label: 'SUBNAVIGATION.BULK_UPLOAD',
  //   routerLink: '/dashboard/settings/bulk-upload',
  //   image: imagesPath + 'attributes.svg',
  //   access: ['0', '1']
  // },
  {
    label: 'SUBNAVIGATION.MATTERPORT',
    routerLink: '/dashboard/settings/matterport',
    access: ['0', '1']
  },
];

export const INTEGRATION_DEFAULT_ITEM: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.INTEGRATION',
    routerLink: '/dashboard/integration/integration',
    image: imagesPath + 'integration.svg',
    access: ['0']
  },
  {
    label: 'SUBNAVIGATION.STATUS_PAGE',
    routerLink: '/dashboard/integration/status-page',
    image: imagesPath + 'integration.svg',
    access: ['0']
  }
];

export const ECOMMERCE_DEFAULT_ITEM: ISubNavigationItem[] = [
  {
    label: 'SUBNAVIGATION.GENERAL',
    routerLink: '/dashboard/ecommerce/general',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.TAX_SETTINGS',
    routerLink: '/dashboard/ecommerce/tax-settings',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.PAYMENT',
    routerLink: '/dashboard/ecommerce/payment',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.SHIPPING_OPTIONS',
    routerLink: '/dashboard/ecommerce/shipping-options',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.SHIPPING_CLASSES',
    routerLink: '/dashboard/ecommerce/shipping-classes',
    access: ['0', '1']
  },
  {
    label: 'SUBNAVIGATION.VOUCHERS',
    routerLink: '/dashboard/ecommerce/vouchers',
    image: imagesPath + 'vouchers.svg',
    access: ['0', '1']
  },
];

