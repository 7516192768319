import { FormGroup } from '@angular/forms';

export function processServerValidationError(form: FormGroup, errors: any): void {
    errors.forEach(element => {
        Object.keys(element).forEach(field => {
            if (form.get(field)) {
                form.get(field)?.setErrors({
                    serverSide: element[field]
                });
            } else {
                form.setErrors({
                    [field]: true
                });
            }
        });
    });
}
