import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiResponse,
    ICreateEditStockRequest,
    IEditStockLoacationReturnDate,
    IIntegrationStockResponse,
    IStockConstants,
    IStockItem,
    IStockLocationHistory,
    IStockSearchTerm,
    IStocksResponse,
    IToggleStockImageVisibleRequest
} from '@models/index';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StockService {

    constructor(
        private _httpClient: HttpClient
    ) { }

    public getStockConstants() {
        return this._httpClient.get<IStockConstants>('get-create-page-data');
    }

    public addStock(data: ICreateEditStockRequest) {
        return this._httpClient.post<any>('stock', data);
    }

    public editStock(data: ICreateEditStockRequest, stockId: string) {
        return this._httpClient.put<any>(`stock/${stockId}`, data);
    }

    public getStockList(page: number) {
        return this._httpClient.get<ApiResponse<[IStocksResponse]>>(`stock?page=${page}`);
    }

    public searchStocks(page: number, searchTerm: IStockSearchTerm) {
        const { search, status, sortField, sortDirection } = searchTerm;
        let params = new HttpParams();
        if (search?.length > 0) {
            params = params.set('search', search);
        }
        if (status !== 'all') {
            params = params.set('status', status);
        }
        if (sortField) {
            params = params.set('field', sortField);
        }
        if (sortDirection) {
            params = params.set('by', sortDirection);
        }
        return this._httpClient.get<IStocksResponse>(`stock-search?page=${page}`, { params });
    }

    public advancedSearch(sendingData: any, page: number) {
        return this._httpClient.post<IStocksResponse>(`stock-search-advanced?page=${page}`, sendingData);
    }

    public getStockById(id: string) {
        return this._httpClient.get<ApiResponse<IStockItem>>(`stock/${id}/edit`);
    }

    public deleteStock(id: string) {
        return this._httpClient.delete<void>(`stock/${id}`);
    }

    public imageUnarchive(ids: string[]) {
        return this._httpClient.post<void>('image-archive', { image_id: ids });
    }

    public toggleImageVisible(data: IToggleStockImageVisibleRequest) {
        return this._httpClient.post<void>('image-visible', data);
    }

    public getLocationHistoryById(stockId: string) {
        const params = new HttpParams().set('stock_id', stockId);
        return this._httpClient.get<ApiResponse<IStockLocationHistory[]>>('location-by-stock-id', { params });
    }

    public addStockLocationHistory(data: IStockLocationHistory) {
        return this._httpClient.post<void>('location-history', data);
    }

    public editStockLocationHistory(data: IStockLocationHistory) {
        return this._httpClient.put<void>(`location-history/${data.stock_location_id}`, data);
    }

    public deleteLocationHistory(locationId: string) {
        return this._httpClient.delete<void>(`location-history/${locationId}`);
    }

    public editReturnedDate(data: IEditStockLoacationReturnDate) {
        return this._httpClient.post<void>('location-date-returned', data);
    }

    public getIntegrations(id: string): Observable<IIntegrationStockResponse> {
        return this._httpClient.get<IIntegrationStockResponse>(`integration-get-stock-data/${id}`);
    }
}
