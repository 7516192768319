import { ILanguage } from '@models/languages/language';

export class RegistrationTranslation {
    subject: { [key: string]: string };
    header_text: { [key: string]: string };
    footer_text: { [key: string]: string };
    verify_your_email_address_phrase: { [key: string]: string };
    email_has_been_sent_phrase: { [key: string]: string };

    constructor(languages: ILanguage[]) {
        languages.forEach(language => {
            this.subject = {
                ...this.subject,
                [language.code]: ''
            };
            this.header_text = {
                ...this.header_text,
                [language.code]: ''
            };
            this.footer_text = {
                ...this.footer_text,
                [language.code]: ''
            };
            this.verify_your_email_address_phrase = {
                ...this.verify_your_email_address_phrase,
                [language.code]: ''
            };
            this.email_has_been_sent_phrase = {
                ...this.email_has_been_sent_phrase,
                [language.code]: ''
            };

        });
    }
}
