<ng-container *ngIf="settings?.option_multilingual_website === 'Multiple Websites'">
    <nz-tabset (nzSelectedIndexChange)="onLanguageChange($event)" class="languages-tab"
        [nzSelectedIndex]="selectedTabIndex">
        <nz-tab *ngFor="let language of languages" [nzTitle]="titleTemplate">
            <ng-template #titleTemplate>
                <img height="16px" [src]="language.flag" alt="">
                <span class="language-tab-label d-block ml-1 mr-1">
                    {{ language.name }}
                </span>
                <ng-container *ngIf="!hideStatusIcons">
                    <img width="25px" height="15px" *ngIf="language.invalid" src="assets/images/error.svg" alt="">
                    <img width="25px" height="15px" *ngIf="!language.invalid && language.isFullyFilled"
                        src="assets/images/success.svg" alt="">
                    <img width="25px" height="15px" *ngIf="!language.invalid && !language.isFullyFilled"
                        src="assets/images/warningg.svg" alt="">
                </ng-container>
            </ng-template>
        </nz-tab>
    </nz-tabset>
</ng-container>