import { YOUTUBE_VIDEO_ID_REGEXP } from '@constants/index';

export class YoutubeHelper {
    // static getVideoId(url: string): string {
    //     const match = url?.match(YOUTUBE_VIDEO_ID_REGEXP);
    //     return (match && match[2].length === 11)
    //         ? match[2]
    //         : null;
    // }

    static getVideoId(url: string): string {
      if (url) {
        let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
        return regex.exec(url)[3];
      } else {
        return '';
      }
    }
}
