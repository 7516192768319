import { VALID_URL_REGEXP, VIMEO_VIDEO_ID_REGEXP, YOUTUBE_VIDEO_ID_REGEXP } from '@constants/index';

export class UrlChecker {

  static isYoutube(url: string): boolean {
    return YOUTUBE_VIDEO_ID_REGEXP.test(url);
  }

  static isVimeo(url: string): boolean {
    const match = url?.match(VIMEO_VIDEO_ID_REGEXP);
    return (match && !!match[2]);
  }

  static isValidUrl(url: string): boolean {
    return RegExp(VALID_URL_REGEXP).test(url);
  }
}
