import { ILanguage } from '@models/languages/language';

export class AttributesTranslate {
  name: { [key: string]: string };
  description: { [key: string]: string };
  constructor(languages: ILanguage[]) {
    languages.forEach(language => {
      this.name = {
        ...this.name,
        [language.code]: ''
      };
      this.description = {
        ...this.description,
        [language.code]: ''
      };
    });
  }
}
