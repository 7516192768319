import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IListViewResponse, IListViewWidgetSave } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class WidgetsService {

  constructor(private _httpClient: HttpClient) { }

  public saveWidget(data: IListViewWidgetSave | {artist_list_item_template: string}) {
    return this._httpClient.post<void>('widgets', data);
  }

  public getWidgets(type: string) {
    return this._httpClient.get<ApiResponse<IListViewResponse>>(`widgets/${type}`);
  }
}
