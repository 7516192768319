import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IContactDetailResponse, IContactsResponse } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private _httpClient: HttpClient) { }

  public get(page?: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<ApiResponse<IContactsResponse>>(`contacts`, {params});
  }

  public getContact(id: string) {
    return this._httpClient.get<IContactDetailResponse>(`contacts/${id}`);
  }

  public delete(id: string) {
    return this._httpClient.delete(`contacts/${id}`);
  }

  public changeType(id: string, type: number) {
    return this._httpClient.put(`contacts/change-type/${id}`, {type});
  }

  public search(data: string) {
    const params = new HttpParams().append('param', data);
    return this._httpClient.get<IContactDetailResponse>('contacts-search', { params });
  }

  public filter(data: string, page: number) {
    const params = new HttpParams()
    .append('type', data)
    .append('page', String(page));
    return this._httpClient.get<ApiResponse<IContactsResponse>>('contacts-filter', { params });
  }

  public sort(key: string, value: string, page: number) {
    const params = new HttpParams()
    .append('page', String(page))
    .append('field', key)
    .append('direction', value);
    return this._httpClient.get<ApiResponse<IContactsResponse>>(`contacts-sort`, {params});
  }

}
