import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGoogleKeys } from '@models/index';

@Injectable({
  providedIn: 'root'
})
export class GoogleKeysService {

  constructor(private _httpClient: HttpClient) { }

  public getGoogleKeys() {
    return this._httpClient.get<IGoogleKeys>(`get-api-keys`);
  }

  public updateGoogleKeys(post: IGoogleKeys) {
    return this._httpClient.put<void>(`update-api-keys`, { data: post });
  }
}
