import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, IOrderDetail, IOrders, IPaginationResponse } from '@models/index';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private _httpClient: HttpClient) { }

  public getAll(page: number) {
    const params = new HttpParams().append('page', String(page));
    return this._httpClient.get<IPaginationResponse<IOrders[]>>(`orders`, { params });
  }

  public getDetail(id: string) {
    return this._httpClient.get<ApiResponse<IOrderDetail>>(`orders/${id}`);
  }

  public put(id: string, data: IOrderDetail) {
    return this._httpClient.put<void>(`orders/${id}`, data);
  }

  public search(s: string, page: number) {
    const params = new HttpParams().append('page', String(page)).append('s', s);
    return this._httpClient.get<IPaginationResponse<IOrders[]>>('orders-search', { params });
  }

}
