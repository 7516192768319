export class LocalStorageHelper {

  /**
   * helper function for get items from localStorage
   * 
   * @param keyName is the key name by which it is stored into localStorage 
   * @param options is the options object with 
   * - `parse`: `boolean` (by default is `false`) 
   * parse or not the data which taken from localStorage
   *
   * @return `T`
   */
  static getItem<T = null>(
    keyName: string,
    options: { parse: boolean } = { parse: true }
  ) {
    const data = window.localStorage.getItem(keyName);
    if (!data) {
      return null;
    }

    let parsedData = null;

    if (options.parse) {
      try {
        parsedData = JSON.parse(data);
      } catch (e) {
        console.warn('invalid JSON format');
      }
    } else {
      parsedData = data;
    }
    return parsedData as T;
  }

  /**
   * helper function for set items into localStorage
   * 
   * @param keyName is the key name by which it should store into localStorage 
   * @param data is the data whci should be store into localStorage
   *
   * @return `void`
   */
  static setItem(
    keyName: string,
    data: any,
  ) {
    data = JSON.stringify(data);
    window.localStorage.setItem(keyName, data);
  }

  /**
   * helper function for remove item from localStorage
   *
   * @param keyName is the key name by which should be removed from localStorage
   *
   * @return `void`
   */
  static removeItem(keyName: string) {
    window.localStorage.removeItem(keyName);
  }

  /**
   * helper function for remove item from localStorage
   *
   * @param keyName is the key name by which should be removed from localStorage
   *
   * @return `void`
   */
  static clean() {
    window.localStorage.clear();
  }
}
