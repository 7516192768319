import { EListViewType } from '@enums/index';
import { IListViewWidgetPreview } from '@models/index';
import { MENU_VIEW_ELEMENT } from './menu-view-element';

export const AVAILABLE_MENU_LIST_WIDGETS: IListViewWidgetPreview[] = [
    {
        name: 'Style 1',
        type: EListViewType.STYLE_ONE,
        image: 'assets/images/widgets/menu-1.png',
        data: MENU_VIEW_ELEMENT,
        // description: 'This is the default list view style from our Infinity template, with beautiful large images in a grid format. Item details are displayed when you hover over the image.'
    },
    {
        name: 'Style 2',
        type: EListViewType.STYLE_TWO,
        image: 'assets/images/widgets/menu-7.png',
        data: MENU_VIEW_ELEMENT,
        // description: 'This is the default list view style from our Silver template, based on a traditional grid with item details displayed below the image.        '

    },
    {
        name: 'Style 3',
        type: EListViewType.STYLE_THREE,
        image: 'assets/images/widgets/menu-2.png',
        data: MENU_VIEW_ELEMENT,
        // description: 'An alternative grid style with gracefully bordered images'
    },
    {
        name: 'Style 4', // Style title decreased because Style 3 is commented
        type: EListViewType.STYLE_FOUR,
        image: 'assets/images/widgets/menu-5.png',
        data: MENU_VIEW_ELEMENT,
        // description: 'This is the default list view style from our Transitions template, demonstrating a gentle animation with the images sliding into place. Instead of using a fixed grid, images are adjusted to fit based on their dimensions. Item details are displayed below the image.'
    },
    {
      name: 'Style 5',
      type: EListViewType.STYLE_FIVE,
      image: 'assets/images/widgets/menu-4.png',
      data: MENU_VIEW_ELEMENT,
      // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
      name: 'Style 6',
      type: EListViewType.STYLE_SIX,
      image: 'assets/images/widgets/menu-6.png',
      data: MENU_VIEW_ELEMENT,
      // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    // {
    //   name: 'Style 7',
    //   type: EListViewType.STYLE_SEVEN,
    //   image: 'assets/images/widgets/menu-7.png',
    //   data: new IListViewWidget(LIST_VIEW_ELEMENTS),
    //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    // }
];
