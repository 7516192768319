export class Util {
  static toBoolean(value: any): boolean {
    switch (value.toLowerCase().trim()) {
      case 'true': case 'yes': case '1': return true;
      case 'false': case 'no': case '0': case null: return false;
      default: return Boolean(value);
    }
  }

  static IsStringJson(text: string): boolean {
    try {
      JSON.parse(text);
    } catch (e) {
      return false;
    }
    return true;
  }

  static swapElementInArray<T>(arr: T[], from: number, to: number): T[] {
    return arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
  }


  static cleanObject(obj: object) {
    for (const propName in obj) {
      if (obj[propName] == null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj;
  }

  public static convertModelToFormData(val: any, formData: FormData = new FormData(), namespace: string = '') {
    if ((typeof val !== 'undefined') && val !== null) {
      if (val instanceof Date) {
        formData.append(namespace, val.toISOString());
      } else if (val instanceof Array) {
        for (let index = 0; index < val.length; index++) {
          const element = val[index];
          this.convertModelToFormData(element, formData, namespace + `[${index}]`);
        }
      } else if (typeof val === 'object' && !(val instanceof File)) {
        for (const propertyName in val) {
          if (val.hasOwnProperty(propertyName)) {
            this.convertModelToFormData(val[propertyName], formData, namespace ? `${namespace}[${propertyName}]` : propertyName);
          }
        }
      } else if (val instanceof File) {
        formData.append(namespace, val);
      } else {
        formData.append(namespace, val.toString());
      }
    }
    return formData;
  }
}
